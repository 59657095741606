/* eslint-disable no-irregular-whitespace */
import Axios from 'axios-observable';
import { getTimezoneName } from '../../Utilities/Utilities';
import { getToken } from './../../Auth/AuthUtilities';

let instance;

//Always the latest token from local store must be used. (renew token scenario Fix ) 
const create_header = () => {
    instance = Axios.create({
        baseURL: process.env.REACT_APP_TELEKARDIA_API_BASE,
        headers: { "Authorization": ` Bearer ${getToken()}` }
    });
}


const create_header_pdf = () => {
    instance = Axios.create({
        baseURL: process.env.REACT_APP_TELEKARDIA_API_BASE,
        headers: {"Authorization": ` Bearer ${getToken()}` }
    });
}

const create_patientheader = () => {
    instance = Axios.create({
        baseURL: process.env.REACT_APP_TELEKARDIA_API_BASE,
        headers: { "Authorization": `q0W8HOK_xjxIpqfQeDnc` }
    });
}

export const endCall = (teamId,clinicianId, appointmentId) => {
    create_header();
    return instance.post(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/endcall`);
}

export const getAppointmentNotes = (teamId,clinicianId, appointmentId) => {
    create_header();
    return instance.get(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notes`);
}

export const appointmentStatusCheck = (teamId,clinicianId, appointmentId,version) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/status`,
    { params: { version: version } }
    );
}


export const addAvailability = (teamId, clinicianId, availabilityData) => {
    create_header();
    return instance.post(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/availability-blocks/`, availabilityData, { params: { timeZoneOffset: (new Date().getTimezoneOffset()),timeZone:getTimezoneName() } });
}
export const deleteAvailability = (teamId, clinicianId, params) => {
    create_header();
    return instance.delete(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/availability-blocks/range`,
     { params: params });
}
export const deleteAvailabilitybyIds = (teamId, clinicianId, params) => {
    create_header();
    return instance.delete(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/availability-blocks/rangeAndId`,
     { params: params });
}
export const deleteAvailabilityNonRecursive = (teamId, clinicianId,availabilityBlockId,params) => {
    create_header();
    return instance.delete(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/availability-blocks/${availabilityBlockId}`,{
        params:params
    });
}
export const deleteAvailabilityRecursive = (teamId, clinicianId,availabilityBlockId, params) => {
    create_header();
    return instance.delete(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/availability-blocks/${availabilityBlockId}/recurring-single`,
    { params: params });
}
export const updateAvailability = (teamId, clinicianId, Id, availabilityData, params) => {
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/availability-blocks/${Id}`, availabilityData, { params: params });
}

export const updateRecursiveAvailability = (teamId, clinicianId, Id, availabilityData, params) => {
    create_header();
    return instance.put(`/api/v2/teams/${teamId}/clinicians/${clinicianId}/availability-blocks/${Id}/recurring-single`, availabilityData, { params: params });
}

export const setAppointmentNotes = (teamId,clinicianId, appointmentId, notes,version) => {

    create_header();
    return instance.post(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notes/appointment-note`, { "note": notes },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}

export const deleteAppointment =(teamId,clinicianId,appointmentId,params)=>{
    create_header();
    return instance.delete(`/api/v1.1/admin/teams/${teamId}/clinicians/${clinicianId}/appointment/${appointmentId}`,{
        params:params
    });
}
export const physicianCalendardeleteappointment =(teamId,clinicianId,appointmentId,params)=>{
    create_header();
    return instance.delete(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointment/${appointmentId}`,{
        params:params
    });
}

export const setConsultationNotes = (teamId,clinicianId, appointmentId, notes) => {
    create_header();
    return instance.post(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notes/consultation-note`, { "note": notes },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}

export const getOverReadReport = (teamId,clinicianId, appointmentId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/overReadReportNotes`);
}



export const setOverReadReport = (teamId,clinicianId, appointmentId, notes) => {
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/overReadReportNotes`, { "note": notes },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}


export const settreatmentPlan = (teamId,clinicianId, appointmentId, notes) => {
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/treatmentPlan`, { "note": notes },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}


export const gettreatmentPlan = (teamId,clinicianId, appointmentId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/treatmentPlan`);
}





export const setconsultationSummaryNotes = (teamId,clinicianId, appointmentId, notes) => {
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/consultationSummaryNotes`, { "note": notes },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}



export const getconsultationSummaryNotes = (teamId,clinicianId, appointmentId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/consultationSummaryNotes`);
}



export const setdurationText = (teamId,clinicianId, appointmentId, durationText) => {
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/durationText`, { "durationText": durationText },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}

export const updateReport = (teamId,clinicianId, appointmentId,body) => {
    create_header();
    return instance.post(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/consultations/`, body,
        {
            params: { timeZoneOffset: (new Date().getTimezoneOffset()),timeZone:getTimezoneName() }
        })
}

export const getdurationText = (teamId,clinicianId, appointmentId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/durationText`);
}

export const getheartConditionId = (teamId,clinicianId, appointmentId, heartConditionId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/heartConditionId`, { "heartConditionId": heartConditionId },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}


export const setheartConditionId = (teamId,clinicianId, appointmentId, heartConditionId) => {
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/heartConditionId`, { "heartConditionId": heartConditionId },
        {
            headers: {
                //'Content-Type': 'text/plain',
                'Authorization': ` Bearer ${getToken()}`
            },
        })
}

export const getMetrics = (teamId, clinicianId, params) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/dashboard-metrics`,
        {
            params: params
        })
}

export const getProviderStatus = (teamId, clinicianId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/status-change`)
}

export const setProviderStatus = (teamId, clinicianId, body) => {
    create_header();
    return instance.post(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/status-change/`, body,
        {
            params: { timeZoneOffset: (new Date().getTimezoneOffset()),timeZone:getTimezoneName() }
        })
}

export const updateAppointmentStatus = (teamId,clinicianId,appointmentId, body) => {
    create_header();
    return instance.put(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/status-change`, body)
}

export const heartConditions = () => {
    create_header();
    return instance.get(`/api/v1/heart-conditions`)
}

export const postConsultation = (teamId,memberId, appointmentId, body) => {
    create_header();
    return instance.post(`/api/v1.1/teams/${teamId}/clinicians/${memberId}/appointments/${appointmentId}/consultations`, body)
}

//==== vonage api calls =====//
export const jwtTokenVonage = (teamId, memberId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${memberId}/generate/vonagetoken`)
}
export const fallBack_jwtTokenVonage = (teamId, memberId,appointmentId) => {
    create_header();
    return instance.get(`/api/v2/teams/${teamId}/clinicians/${memberId}/appointments/${appointmentId}/generate/vonagetoken`)
  }
  export const vonage_reconnect = (teamId, memberId,appointmentId) => {
    create_header();
    return instance.get(`/api/v2/teams/${teamId}/clinicians/${memberId}/appointments/${appointmentId}/vonagetoken/reconnect`)
  }
  export const av_reconnect = (teamId, memberId,appointmentId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${memberId}/appointments/${appointmentId}/av/reconnect`)
  }
  export const fallBack_CallMetrics = (teamId, memberId,appointmentId,conversationId) => {
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/clinicians/${memberId}/appointments/${appointmentId}/conversation/${conversationId}`)
  }
  //===================//
export const helpNumber = () => {
    create_header();
    return instance.get(`/api/v1/support/contactinfo`);
}
export const reportFooter = (teamId) => {
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/info`);
}

export const AvailabledoctorList =(teamId,params)=>{
    create_header();
    return instance.get(`/api/v3/teams/${teamId}/clinicians/availability-blocks/available-clinicians`,
    {
        params: params
    })
}
export const AvailabledoctorListv2 =(teamId,adminId,params)=>{
    create_header();
    return instance.get(`/api/v3/teams/${teamId}/admin/${adminId}/availability-blocks/available-clinicians`,
    {
        params: params
    })
}
export const ReAssignAppointment = (teamId,clinicianId,appointmentId,body,params)=>{
    create_header();
    return instance.put(`/api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/reassign`,body,{
        params:params
    })

}

export const AdminReAssignAppointment = (teamId,userId,appointmentId,body,params)=>{
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/admin/${userId}/appointments/${appointmentId}/reassign`,body,{
        params:params
    })
}
export const AlldoctorsList =(teamId,clinicianId)=>{
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/admin/${clinicianId}/clinicians`)
}

export const getAvailabilityData = (teamId, clinicianId, queryParams) => {
    create_header();
    return instance.get(`/api/v2/teams/${teamId}/admin/${clinicianId}/clinician-calendars`, {params: queryParams});
}

export const getClinicianAvailabilityslots = (teamId,memberId,queryParams) =>{
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${memberId}/availability-blocks`,
    {params:queryParams});
}
export const getClinicianAvailabilityslotsv2 = (adminId,teamId,memberId,queryParams) =>{
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/admin/${adminId}/clinicians/${memberId}/availability-blocks`,
    {params:queryParams});
}

export const RescheduleAppointment = (teamId,userId,appointmentId,body,params)=>{
    create_header();
    return instance.put(`/api/v1/teams/${teamId}/admin/${userId}/appointments/${appointmentId}/reschedule`,body,{
        params:params
    });
}

export const chatToken = (teamId,userId,appointmentId)=>{
    create_header();
    return instance.get(`/api/v1/teams/${teamId}/clinicians/${userId}/appointments/${appointmentId}/chat/token`);
}

export const patientToken = (appointmentId)=>{
    create_patientheader();
    return instance.get(`/api/v1/patients/2iik80h13leb5q8osgsyvesvz/appointments/${appointmentId}/chat/token`);
}


export const getpdf = () => {
    create_header_pdf();
    return instance.get(`/i/v1/summary_report?endTime=2021-05-05T00%3A00%3A00.000Z&startTime=2021-04-01T00%3A00%3A00.000Z`,{
        "Authorization": "fgfE1HCZBxpHrw9eqRWS",
        
        });
}


export const getbphistorypdf = (teamId, clinicianId, queryParams) => {
    create_header_pdf();
    return instance.post(`/api/v1/teams/${teamId}/clinicians/${clinicianId}/summary-pdf`, queryParams);
}

