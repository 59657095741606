import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import * as API from "./../../Services/API/actions";
import { useSelector } from "react-redux";


const BootstrapInput = withStyles((theme) =>
    createStyles({
        root: {
            margin: 0,
            'label + &': {
                marginTop: theme.spacing(3),
                fontSize: 16,
                color: '#000 !important',
                width: "100%"
            },
            '& .Mui-error':{
                borderColor:"#FF0000"
              },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border:'1px solid #d8d8d8',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                'inherit',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#142A39',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            '& .Mui-error':{
                borderColor:"#FF0000"
              },
        },
    }),
)(InputBase);

const useStyles = makeStyles((theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
        },
    }),
);

export default function CustomizedSelects(props) {
    const classes = useStyles();
    const clinicianId = useSelector((state) => state.user.profile.id);
    const teamId = useSelector((state) => state.user.team.id);
    useEffect(() => {            
        if (!props.disable) {
          API.getheartConditionId(
            teamId,
            clinicianId,
            props.appointmentId
          ).subscribe(
            (res) => {
              res.data.result !== null
                ? props.handleidChange({
                    target: {
                      value: res.data.result,
                    },
                  })
                : props.handleidChange({
                    target: {
                      value: 0,
                    },
                  });
            },
            (error) => console.log(error)
          );
        }
      }, []);

    const changeheartid =(heartid) =>{
        // if(heartid!==0)
        props.setError(false);
        props.handleidChange(heartid);
    };

      
    return (
        <div className="d-block w-100">
            <FormControl className={classes.margin}>
                <InputLabel id="demo-customized-select-label" style={{color:props.error?"#FF0000":''}}>Select EKG Interpretation</InputLabel>
                <Select
                    displayEmpty
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    defaultValue="Select EKG Interpretation"
                    value={props.id}
                    onChange={changeheartid}
                    input={<BootstrapInput error={props.error} />}
                    
                >
                    {
                        props.heartConditions.map((item, index) => (
                            <MenuItem key={index} value={item.id} >{item.heartCondition}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
    );
}
