import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ROUTES } from "../chart/Constants";
import withStartConsultation from "../Dashboard/withStartConsultation";

const ToastButton = ({
  checkStatus,
  patientDetails,
  patientDetailsLoader,
  id,
  reviewProfile,
  waitingroomAlert,
  buttonProps,
  reviewSession,
  memberInWaitingRoom,
  alertCloseFlag,
  hideToast,
}) => {
  const [reviewClicked, setreviewClicked] = useState(false);
  const [waitroomClicked, setwaitroomClicked] = useState(false);

  useEffect(() => {
    if (
      reviewClicked &&
      patientDetails &&
      patientDetails.appointmentId &&
      id &&
      !patientDetailsLoader
    ) {
      reviewProfile();
      setreviewClicked(false);
    } else if (waitroomClicked) {
      waitingroomAlert();
      setwaitroomClicked(false);
    }
  }, [
    reviewClicked,
    waitroomClicked,
    patientDetails,
    patientDetailsLoader,
    id,
  ]);

  useEffect(() => {
    if (alertCloseFlag) {
      console.log("in toast alert... ");
      hideToast();
    }
  }, [alertCloseFlag]);

  const history = useHistory();
  const toastButtonClick = () => {
    if (memberInWaitingRoom) {
      setwaitroomClicked(true);
    } else if (reviewSession) {
      setreviewClicked(true);
      localStorage.setItem("_mod", JSON.stringify(true));
      // history.push(`${ROUTES.MEMBER_PROFILE}/${patientDetails?.patient?.patientId}`);
    }
  };
  return (
    <Button
      onClick={toastButtonClick}
      style={{ marginLeft: "20px", color: "#fff", fontWeight: "bold" }}
      {...buttonProps}
    >
      {memberInWaitingRoom
        ? "JOIN CONSULTATION"
        : reviewSession
        ? "REVIEW FILE"
        : "REJOIN"}
    </Button>
  );
};

export default withStartConsultation(ToastButton);
