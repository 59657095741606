/* eslint-disable */
import * as API from "./../../Services/API/actions";
import moment from "moment";
import { useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Statistics from "./statistics";
import PropTypes from "prop-types";
import { TeleKardiaAPI } from "./../../Axios/axios";
import { getToken } from "./../../Auth/AuthUtilities";
import PatientDetails from "../Appointments/Calendar/PatientDetails";
import EkgRecording from "../chart/EkgRecording";
import { calcRowSeconds } from "../chart/utils";
import Alert from "@material-ui/lab/Alert";
import * as Lib from "../chart/Constants/RecordingConstants";
import _get from "lodash.get";
import { useTranslation } from "react-i18next";
import { getEKGAlgorithmDeterminationDisplayText } from "../../Utilities/recordingMaps";
import { useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import EcgLoader from "../Shared/EcgLoader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useResizeDetector from "use-resize-observer/polyfilled";
import debounce from "lodash.debounce";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    borderBottom: "1px solid #e8e8e8",
    backgroundColor: "transparent",
    boxShadow: "none !important",
  },
  paperroot: {
    flexGrow: 1,
    flexDirection: "row",
    // justifyContent: "flex-end",
    boxShadow: "none !important",
  },
  customTabRoot: {
    color: "#142A39",
    backgroundColor: "#fff !important",
    borderBottom: "1px solid #e8e8e8",
    padding: "5px",
    boxShadow: "none !important",
    minWidth: 75,
    fontWeight: 700,
  },
  customTabIndicator: {
    backgroundColor: "#2d9f86",
    height: "4px",
  },
  blackGrid: {
    fontWeight: "600",
    alignItems: "center",
    height: "55px",
    padding: theme.spacing(1),
    background: "#E2E7E3",
    color: "#000000",
    lineHeight: "40px",
  },
  tabRoot: {
    background: "#fff !important",
    fontSize: "14px",
    fontWeight: "bold",
    opacity: "0.54",
    minWidth: 75,
    // fontFamily: ["Work Sans"].join(","),
    fontFamily: "Work Sans !important",
    textTransform: "uppercase !important",
    boxShadow: "none !important",
  },
  label: {
    fontFamily: "Work Sans !important",
    fontWeight: "700 !important",
  },
  tabvaluestyle: {
    // minWidth: "130px",
  },
  tabvaluesmallstyle: {
    minWidth: "auto",
  },
  recordingInfo: {
    zIndex: 1,
    padding: "8px 10px",
    backgroundColor: "#fff",
    opacity: 0.8,
    width: "fit-content",
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    right: "10px",
    margin: "20px",
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: "20px",
    color: "#000",
  },
  recordingInfospana: {
    // width: "30px",
    paddingRight: "20px",
    color: "#4183c4 !important",
  },
  recordingInfospanfirstChild: {
    width: "30px",
    paddingLeft: "0px",
  },
}));

const contentwrapperstyles = {
  height: "500px",
  borderRadius: "4px",
  boxShadow: "0px 2px 3px 0px light-grey",
  backgroundColor: "#FFFFFF",
  position: "relative",
};

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const ScrollableTabsButtonAuto = (props) => {
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const mediawidth = useWidth();
  const [numPages, setNumPages] = useState(null);
  const [pdfwidth, setpdfwidth] = useState(1000);
  const [ekgdivwidth, setekgdivwidth] = useState(1000);

  function handleekgdivResize({ width }) {
    if (ekgdivwidth !== width && width !== 0) {
      setekgdivwidth(width);
    }
  }

  function handleResize({ width }) {
    if (pdfwidth !== width && width !== 0) {
      setpdfwidth(width);
    }
  }
  const pdfref = useRef(null);
  const ekgref = useRef(null);

  const delay = 30;

  useResizeDetector({
    ref: pdfref,
    onResize: debounce(handleResize, delay, { maxWait: 100 }),
  });

  useResizeDetector({
    ref: ekgref,
    onResize: debounce(handleekgdivResize, delay, { maxWait: 100 }),
  });

  function handlecommunicationResize({ height }) {
    console.log("comunicationheight", height);
    if (comunicationheight !== height) {
      setcomunicationheight(height);
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [startDate] = useState(new Date());
  const pattern = "YYYY-MM-DDT00:00:00.000";
  const eodpattern = "YYYY-MM-DDT23:59:00.000";
  const summaryReportRequest = {
    endDateTime: moment(endOfMonth(startDate)).format(eodpattern),
    patientId:
      _get(props, "patientDetails.patient.patientId") ||
      _get(props, "event.patient.patientId") ||
      "-",
    startDateTime: moment(startOfMonth(startDate)).format(pattern),
  };

  const [pdfdata, setpdfdata] = useState("");

  useEffect(() => {
    console.log('recordings -- ', props.recording);
    API.getbphistorypdf(teamId, clinicianId, summaryReportRequest).subscribe(
      (res) => {
        console.log("res", res);
        setpdfdata(res.data.result);
        // res.data.result !== null &&
      },
      (error) => console.log(error)
    );
  }, []);

  const classes = useStyles();
  const { t } = useTranslation();
  const MIN_SECONDS = 1;
  const mainContentWidth = ekgdivwidth;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0
      ? calcRowSeconds(mainContentWidth)
      : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;
  return (
    <div className="row">
      <div className="col-12 bg_white p-0">
        <div className={classes.root + " position_rel"}>
          <AppBar position="static" color="default">
            <Tabs
              className="custom_tab"
              classes={{
                root: classes.customTabRoot,
                indicator: classes.customTabIndicator,
              }}
              value={props.value}
              onChange={props.handleChange}
              scrollButtons="auto"
              centered
              variant="fullWidth"
            >
              <Tab
                label="EKG Details"
                classes={{ root: classes.tabRoot }}
                {...a11yProps(0)}
              />
              <Tab
                label="Patient Details"
                classes={{ root: classes.tabRoot }}
                {...a11yProps(1)}
              />
              <Tab
                label="EKG / BP HISTORY"
                classes={{ root: classes.tabRoot }}
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>

          <>
            <TabPanel value={props.value} index={0}>
              <div
                className={
                  props.alertOn
                    ? "col-12 p-4 set_height_no_ekg"
                    : "col-12 p-4 set_height"
                }
                ref={ekgref}
              >
                {props.alertOn ? 
                  <Alert severity="warning">Unable to fetch EKG result at the moment</Alert>
                  : 
                  props.noEKG
                  ? <Alert severity="warning">No EKG Provided...</Alert>
                  : (
                  <>
                    <div
                      className={
                        classes.blackGrid +
                        " pl-2 d-flex justify-content-between"
                      }
                    >
                      <div>
                        {t("EKG Finding")}
                        {" : "}
                        {getEKGAlgorithmDeterminationDisplayText(
                          props.recording.algorithmDetermination,
                          props.recording.algorithmPackage
                        )}
                      </div>
                      <div>
                        {t("Device Used")}
                        {" : "}
                        {props.recording && props.recording.is6l
                          ? "6-Lead Device"
                          : "1-Lead Device"}
                      </div>
                    </div>
                    <div className={classes.recordingInfo}>
                      <div className={classes.recordingInfospan}>25 mm/s</div>
                      <div className={classes.recordingInfospan}>10 mm/mV</div>
                      {!props.recording.is6l && (
                        <div className={classes.recordingInfospana}>
                          {" "}
                          <a
                            onClick={() =>
                              props.setoggleRecordingInvert(
                                !props.toggleRecordingInvert
                              )
                            }
                          >
                            {" "}
                            {t("Invert ECG")}
                          </a>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        props.recording.is6l ? "EKG_canvas" : "EKG_canvas_non6L"
                      }
                    >
                      <EkgRecording
                        height={621}
                        width={ekgWidth}
                        recording={props.recording}
                        calpulse={false}
                        rowSeconds={rowSeconds}
                        inverted={props.toggleRecordingInvert}
                        is6l={props.recording.is6l}
                      ></EkgRecording>
                    </div>
                    <div className="col-12 height_300 tags_small bg_white p-4">
                      <div
                        className="row no-gutters"
                        style={{ display: "-ms-flexbox" }}
                      >
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                          <h5 className="heading">{t("EKG Tags")}</h5>
                          <div>
                            {!props.recording && props.recording.tags.length > 0
                              ? props.recording.tags.map((item, index) => (
                                  <ul
                                    className="list-unstyled mb-0"
                                    key={index}
                                  >
                                    <li className="my-2">{item}</li>
                                  </ul>
                                ))
                              : "-"}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                          <h5 className="heading">{t("EKG Details")}</h5>
                          <div>
                            <ul className="list-unstyled mb-0">
                              <li className="my-2">
                                {t("Heart Rate:")}{" "}
                                <span>
                                  {_get(props.recording, "bpm") ||
                                  _get(props.recording, "bpm") === 0
                                    ? _get(props.recording, "bpm") +
                                      " " +
                                      t("BPM")
                                    : "-"}
                                </span>
                              </li>
                              <li className="my-2">
                                {t("Recorded Date")}:{" "}
                                <span>
                                  {_get(props.recording, "recordedAt")
                                    ? moment(
                                        new Date(
                                          _get(props.recording, "recordedAt")
                                        )
                                      ).format("MMMM D, YYYY")
                                    : "-"}
                                </span>
                              </li>
                              <li className="my-2">
                                {t("Recorded Time")}:{" "}
                                <span>
                                  {" "}
                                  {_get(props.recording, "recordedAt")
                                    ? moment(
                                        new Date(
                                          _get(props.recording, "recordedAt")
                                        )
                                      ).format("hh:mm A")
                                    : "-"}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel value={props.value} index={1}>
              <div className="col-12 p-4 set_height_patientdetails_Appointments">
                <PatientDetails
                  patientDetails={props.patientDetails}
                  appointmentId={props.appointmentId}
                  stepper={props.step}
                  {...props}
                />
                <div className="row no-gutters">
                  <div className="col-7">
                    <div className="col-12">
                      <h5 className="heading"> {t("Medical History")} </h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(props.patientDetails, "medicalHistory") ||
                                "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Family History")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(
                                props.patientDetails,
                                "familyMedicalHistory"
                              ) || "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Allergies")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(props.patientDetails, "allergyDetails") ||
                                "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Social History")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          {Object.keys(props.patientDetails).length > 0 &&
                          props.patientDetails.lifeStyleHistoryStr ? (
                            props.patientDetails.lifeStyleHistoryStr
                          ) : (
                            <li className="my-2"> - </li>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Surgeries")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(props.patientDetails, "surgeryDetails") ||
                                "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Notes from patient")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(props.patientDetails, "patientNotes") ||
                                "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-12">
                    <h5 className="heading">{t("Medications")}</h5>
                    <div>
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th scope="col">{t("Medication Name")}</th>
                            <th scope="col">{t("Dose")} </th>
                            <th scope="col">{t("Unit")}</th>
                            <th scope="col">{t("Frequency")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.patientDetails &&
                          Object.keys(props.patientDetails).length > 0 &&
                          props.patientDetails.medicationsArray &&
                          props.patientDetails.medicationsArray.length > 0 ? (
                            props.patientDetails.medicationsArray.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{item.name}</td>
                                  <td>{item.dose}</td>
                                  <td>{item.unit}</td>
                                  <td>{item.frequency}</td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>{"-"}</td>
                              <td>{"-"}</td>
                              <td>{"-"}</td>
                              <td>{"-"}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={props.value} index={2}>
              <div className="col-12 p-4 set_height_pdf" ref={pdfref}>
                <Document
                  file={`data:application/pdf;base64,${pdfdata}`}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <div style={contentwrapperstyles}>
                      <EcgLoader />
                    </div>
                  }
                  noData={
                    <div style={contentwrapperstyles}>
                      <EcgLoader />
                    </div>
                  }
                  error={
                    <div style={contentwrapperstyles}>
                      <EcgLoader />
                    </div>
                  }
                >
                  {[...Array(numPages).keys()].map((p) => (
                    <Page pageNumber={p + 1} width={pdfwidth} />
                  ))}
                </Document>
              </div>
            </TabPanel>
          </>
        </div>
      </div>
    </div>
  );
};
export default ScrollableTabsButtonAuto;
