import * as API from './../../Services/API/actions'
import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Constants from './../../Utilities/Constants'
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import {
  Typography,
  Grid,
  Badge,
  Select,
  MenuItem,
  ClickAwayListener,
  IconButton
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useSelector } from "react-redux";
import { ChatContext } from '@alivecor/strophe2.0';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
  customTabRoot: {
    color: "#2d9f86",
  },
  customTabIndicator: {
    backgroundColor: "#2d9f86",
    color: "#2d9f86",
  },
  customBadge: {
    backgroundColor: "#2d9f86",
    color: "white",
  },
  boldFont: {
    fontWeight: "600",
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  minheightCloumn: {
    height: "100px",
  },
  selectEmpty: {
    "&:before": {
      borderColor: "#2d9f86",
      border: "0px"
    },
    "&:after": {
      borderColor: "#2d9f86",
      border: "0px"
    },
  },
  tooltip: {
    fontSize: "16px",
    background: "#fff",
    color: "#000",
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid rgba(0,0,0,0.16)",
      backgroundColor: "#fff",
      boxSizing: "border-box"
    }
  }
}));

export default function ProviderStatus(props) {
  const { status } = useContext(ChatContext);
  const classes = useStyles();
  const clinicianid = useSelector(state => state.user.profile.id);
  const providername = useSelector(state => state.user.profile.firstName);
  const teamId = useSelector(state => state.user.team.id);
  const [stats, setstats] = React.useState({});
 const [number,setnumber] = React.useState();
 const [istooltipopen, setistooltipopen] = React.useState(false);

 const handleTooltipClose = () => {
  setistooltipopen(false);
};

const handleTooltipOpen = () => {
  setistooltipopen(true);
};
 
  let subscription
  useEffect(() => {
    API.helpNumber().subscribe(
      res=>{
        setnumber(res.data["result"]);
      },
      error=>{
            console.log(error)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscription = API.getProviderStatus(props.userstate.data.teams[0].id,props.userstate.data.profile.id).subscribe(res => {
      if (res.data.success) {
        setstats(res.data.result.status);
      }
      else {
        if (res.data.result == null) {
          // set the provider status to offline initially
          putStatus(1);
        }
      }
    }, error => {
      //handle error
    })

    return () => {
      if (subscription)
        subscription.unsubscribe();
    }
  }, []);

  const handleChange = (evt) => {
    putStatus(evt.target.value);
  };

  const putStatus = (status) => {

    let body = {
      "onDutyStatus": status
    }

    subscription = API.setProviderStatus(teamId, clinicianid, body)
      .subscribe(res => {
        setstats(res.data.result.status);
      }, error => {
        //handle error
      })

    setstats(status);
  }
  return (
    <div className="telekardia">
    <div className="row no-gutters px-2 py-2">
      <div className="col-6 d-xl-none">
      {/*<div className="d-flex flex-row mt-2">*/}
      {/*  <div className="bars">*/}
      {/*    <MenuIcon />*/}
      {/*  </div>*/}
      {/*  <div className="logo_text ml-3">KardiaPro</div>*/}
      {/*</div>*/}
      </div>
      <div className="col text-right">
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={0}
        >
          <Badge
            overlap="circle"
            badgeContent={
              <CheckCircleIcon
                style={{
                  color:
                    stats === Constants.ProviderStatus.Available
                      ? "#6BC077"
                      : "#BABFBD",
                }}
              />
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            className="user_icon mr-3"
          > 
            <AccountCircleIcon style={{ color: "#BABFBD", fontSize: 55 }} />
          </Badge>
          <Grid item>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              className="status_font"
            >
              <div style={{display:'flex'}}>
              <Typography className={classes.boldFont + " text-truncate"} style={{textAlign:'left'}} title={"Dr." + providername}>
                Dr. {providername}
              </Typography>
              <div
                    style={{
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      backgroundColor:
                        status === 5
                          ? 'green'
                          : status === 4
                          ? 'red'
                          : 'grey',
                    }}
                  ></div>
              </div>
              <Grid container item>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stats}
                  className={classes.selectEmpty + " status_txt"}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  onChange={(event) => handleChange(event)}
                >
                  <MenuItem value={Constants.ProviderStatus.Available}>
                    Available
                  </MenuItem>
                  <MenuItem value={Constants.ProviderStatus.Offline}>
                    Offline
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            TransitionComponent={Fade}
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            arrow
            open={istooltipopen}
            TransitionProps={{ timeout: 600 }}
            title={
              <div style={{ whiteSpace: "pre-line" }}>
               
                <Typography variant="h6" component="h6" gutterBottom>
                  Do you need help?
                </Typography>
                {"Please contact the administrator for any issues: \n"}
                <Grid
                  container
                  direction="row"
                  style={{ display: "-ms-flexbox" }}
                >
                  <Grid item>
                    {" "}
                    <Typography variant="body1" component="body1" gutterBottom>
                      Ph:
                    </Typography>
                  </Grid>
                  <Grid item>
            <h6 className="caption">{number}</h6>
                  </Grid>
                </Grid>
                
              </div>
            }
          >
             <IconButton aria-label="delete" onClick={handleTooltipOpen}>
            <HelpOutlineIcon              
              className="help_icon mr-2 ml-2"
              style={{ color: "#2D9F86", fontSize: 45 }}
            />
            </IconButton>
          </Tooltip>
          </ClickAwayListener>
        </Grid>
      </div>
    </div>
    </div>
  );
}
