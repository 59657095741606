import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react'
import { Strophe, $pres, $msg } from 'strophe.js/src/strophe'
import moment from 'moment'
import axios from 'axios'
export function getFullJid(jid) {
  let { hostname } = new URL(process.env.REACT_APP_CHAT_DOMAIN)
  return `${jid}@${hostname}`
}

const options = {
  mechanisms: [Strophe.SASLMD5]
}

const CHAT_ENDPOINT = `${process.env.REACT_APP_CHAT_API_BASE}bosh`
const connection = new Strophe.Connection(CHAT_ENDPOINT, options)

const useAcStrophe = (userId, password) => {
  /**
   * Status - Status of the user trying to connect to chat server
   * recStatus - Status of the reciever (*Need prescence to be enabled on both sides)
   * typing - typing Status of the reciever (*Need prescence to be enabled on both sides)
   * allMessages - History
   */
  const [status, setStatus] = useState(false)
  const [recStatus, setRecStatus] = useState(false)
  const [typing, setTyping] = useState(false)
  const [unreadMsgCount, setUnreadMsgCount] = useState({})
  const [allMessages, setAllMessages] = useState({})
  const [currentAppointmentUser, setCurrentAppointmentUser] = useState(null)
  const [currentChatUser, setCurrentChatUser] = useState(null)

  const retryConnection = (userId, password) => {
    connectToChatServer(getFullJid(userId), password)
  }
  const pres = useRef(null)
  const rec = useRef(null)
  const deleteAllHandlers = () => {
    if (pres.current && rec.current) {
      connection.deleteHandler(pres)
      connection.deleteHandler(rec)
    }
  }

  useEffect(() => {
    //trying to connect with chat server
    if (userId && password) {
      connectToChatServer(getFullJid(userId), password)
    }
    return () => {
      connection.reset()
      console.log('deleting handlers')
      deleteAllHandlers()
    }
  }, [userId, password])

  useEffect(() => {
    if (currentChatUser) {
      unreadMsgCount[getFullJid(currentChatUser)] &&
        setUnreadMsgCount((d) => {
          d[getFullJid(currentChatUser)] = 0
          return d
        })
    }
  }, [allMessages])

  /**
   * @name onConnect
   * @desc Callback function to notify user connection status.
   * @param {*} status
   */

  const onConnect = function (status) {
    setStatus(status)
    if (status === Strophe.Status.CONNECTED) {
      connection.send($pres())
      rec.current = connection.addHandler(
        onMessageRecieved,
        null,
        'message',
        null,
        null,
        null
      )
      pres.current = connection.addHandler(onPresence, null, 'presence')
    }
  }

  /**
   * @name connectToChatServer
   * @desc Connecting to chat server
   * @param {*} jid
   * @param {*} password
   */
  const connectToChatServer = (jid, password) => {
    connection.connect(jid, password, onConnect)
  }

  /**
   * @name connectToChatServer
   * @desc Getting the prescence of the reciever
   * @param {*} presence
   */
  function onPresence(presence) {
    var presence_type = presence.getAttribute('type') // unavailable, subscribed, etc...
    // var from = presence.getAttribute("from"); // the jabber_id of the contact
    if (!presence_type) {
      presence_type = 'online'
      setRecStatus('Online')
    }
    if (presence_type !== 'error') {
      if (presence_type === 'unavailable') {
        // Mark contact as offline
        setRecStatus('Offline')
      } else {
        var show = presence.getElementsByTagName('show')
        const showText = show.length > 0 ? Strophe.getText(show[0]) : ''
        // this is what gives away, dnd, etc.
        if (showText === 'chat' || showText === '') {
          setRecStatus('Online')
          // Mark contact as online
        } else {
          // etc...
          setRecStatus(showText)
        }
      }
    }
    return true
  }

  /**
   * @name log
   * Log the message onto the screen
   * @param {*} status
   */

  function log(msg, isOwn = false) {
    setAllMessages((msgs) => {
      const result = { ...msgs }
      const [source, text, timestamp] = msg.split(':')
      const [from] = source.split('@')
      result[from] = {
        source: source,
        text: text,
        time: parseInt(timestamp),
        isOwn
      }
      return result
    })
  }
  /**
   * Message Handler
   * trigger when someone send us message.
   * @param {*} msg
   * @returns
   */
  function onMessageRecieved(msg) {
    //when any incoming message is received from chat server.
    // console.log(msg)
    // console.log('offline - ', msg.getElementsByTagName('delay').length)
    // const to = msg.getAttribute("to");
    const from = msg.getAttribute('from')
    const isModUser = from.toString().includes('_mod')
    const type = msg.getAttribute('type')
    if (type && !isModUser) {
      const elems = msg.getElementsByTagName('body')
      const composing = msg.getElementsByTagName('composing')
      const paused = msg.getElementsByTagName('paused')
      if (Object.values(composing).length > 0) setTyping(true)
      if (Object.values(paused).length > 0) setTyping(false)
      if (type === 'chat' && elems.length > 0) {
        var body = elems[0]
        console.log(
          'CHAT: I got a message from ' + from + ': ' + Strophe.getText(body)
        )
        let bareJid = Strophe.getBareJidFromJid(from.toLowerCase())
        setUnreadMsgCount((prev) => ({
          ...prev,
          [bareJid]: prev[bareJid] ? prev[bareJid] + 1 : 1
        }))
        log(from + ':' + Strophe.getText(body) + ':' + Date.now())

        setTyping(false)
      }
    } else if (isModUser && msg.getElementsByTagName('delay').length == 0) {
      const elems = msg.getElementsByTagName('body')
      // console.log(elems)
      elems.length > 0 &&
        console.log(
          JSON.parse(Strophe.getText(elems[0]).replace(/&quot;/g, '"'))
        )
      const dataFromMod =
        elems.length > 0 &&
        JSON.parse(Strophe.getText(elems[0]).replace(/&quot;/g, '"'))

      dataFromMod && setCurrentAppointmentUser(dataFromMod)
    }
    // we must return true to keep the handler alive.
    // returning false would remove it after it finishes.
    return true
  }

  /**
   * Fetch chat history based on user id
   * @param {*} jid - your id
   * @param {*} withJid - to whom you want chat history user id
   * @param {*} messageCount - no. of messages
   * @param {*} callback
   */
  const fetchAllChatHistory = async (
    jid,
    withJid,
    messageCount = 1,
    callback
  ) => {
    connection.mam.query(Strophe.getBareJidFromJid(jid), {
      with: withJid,
      before: '',
      max: messageCount,
      onMessage: function (message) {
        let messageDetails = message.getElementsByTagName('message')[0]
        let [from] = messageDetails.getAttribute('from').split('@')
        let to = messageDetails.getAttribute('to')
        // let type = messageDetails.getAttribute("type");
        let messageText = message.getElementsByTagName('body')[0]?.textContent
        let timestamp = messageDetails
          .getElementsByTagName('stanza-id')[0]
          .getAttribute('id')
        // let msgId = messageDetails.getAttribute("id");
        const result = { ...allMessages }
        const chatUser = from === userId ? to : from
        const isOwn = from === userId
        result[chatUser] = {
          source: chatUser,
          text: messageText,
          time: new Date(Math.round(timestamp / 1000))
        }

        log(chatUser + ':' + messageText + ':' + timestamp / 1000, isOwn)
        callback()
        return true
      },
      onComplete: function (response) {}
    })
  }

  return {
    status,
    recStatus,
    typing,
    allMessages,
    connection,
    currentAppointmentUser,
    currentChatUser,
    userId,
    password,
    unreadMsgCount,
    setCurrentAppointmentUser,
    fetchAllChatHistory,
    setCurrentChatUser,
    log,
    setUnreadMsgCount,
    retryConnection
  }
}

export const initChatWithUser = (chatUserId) => {
  const {
    status,
    recStatus,
    typing,
    allMessages,
    connection,
    currentAppointmentUser,
    setCurrentChatUser,
    fetchAllChatHistory,
    log,
    setUnreadMsgCount,
    userId,
    password,
    retryConnection
  } = useContext(ChatContext)

  const [messages, setMessages] = useState({})
  const [chatFirstMessage, setChatFirstMessage] = useState()
  const [fetchingHistory, setFetchingHistory] = useState(false)
  const myMessage = allMessages && allMessages[chatUserId]
  useEffect(() => {
    if (status == 6) {
      console.log('RETRYING CONNECTION!')
      retryConnection(userId, password)
    }
  }, [status])

  useEffect(() => {
    setChatFirstMessage()
    setMessages({})
    setCurrentChatUser(chatUserId)
  }, [chatUserId])

  //catching incoming messages and adding them to the sender's chat pool
  useEffect(() => {
    if (myMessage && !myMessage.isOwn) {
      setMessages((prev) => messageGroupByDay(prev, myMessage))
    }
    setUnreadMsgCount((prev) => ({ ...prev, [chatUserId]: 0 })) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myMessage, chatUserId])

  const messageGroupByDay = (prevMessages, currentMessage) => {
    let timeStampKey = moment(new Date(currentMessage['time'])).startOf('day')
    return {
      ...prevMessages,
      [timeStampKey]: prevMessages[timeStampKey]
        ? [...prevMessages[timeStampKey], currentMessage]
        : [currentMessage]
    }
  }

  const convertMessageXMLtoJson = (message) => {
    let messageDetails = message.getElementsByTagName('message')[0]
    let from = messageDetails.getAttribute('from')
    let messageText = message.getElementsByTagName('body')[0]?.textContent
    let msgId = messageDetails.getAttribute('id')
    let timestamp = messageDetails
      .getElementsByTagName('stanza-id')[0]
      .getAttribute('id')
    return {
      source: from,
      text: messageText.toString(),
      time: timestamp / 1000,
      id: msgId,
      status: 2
    }
  }

  /**
   * Fetch chat history based on user id
   * @param {*} jid - your id
   * @param {*} withJid - to whom you want chat history user id
   * @param {*} messageCount - no. of messages
   * @param {*} callback
   */
  const getHistory = (filters, list, callback) => {
    //cleanup current messages
    setMessages({})
    let localMsg = {}
    let localChatFirstMessage = null
    let isPagination = filters?.after || filters?.before
    setFetchingHistory(true)
    connection.mam.query(Strophe.getBareJidFromJid(getFullJid(userId)), {
      max: 50,
      ...filters,
      onMessage: function (message) {
        const messageObj = convertMessageXMLtoJson(message)
        localChatFirstMessage = localChatFirstMessage
          ? localChatFirstMessage
          : messageObj?.id
          ? messageObj
          : null
        if (!list) {
          localMsg = messageGroupByDay(localMsg, messageObj)
        } else {
          callback(messageObj.text)
          return messageObj.text
        }
        return true
      },
      onComplete: function (response) {
        let isPaginationComplete = response
          .getElementsByTagName('fin')[0]
          .getAttribute('complete')
        setMessages(localMsg)
        if (isPaginationComplete === 'false' && localChatFirstMessage) {
          setChatFirstMessage(localChatFirstMessage)
        }
        setFetchingHistory(false)
      }
    })
  }
  /**
   *
   * @param {*} sourceUserId the coach id
   * @param {*} targetChatUserId the chat target user id
   * @param {*} size no of messages
   * @param {*} lastMessageId nth message id
   * @param {*} token jwt
   */
  const getHistoryFromTk = useCallback(
    (sourceUserId, targetChatUserId, size, lastMessageId, token) => {
      setFetchingHistory(true)

      axios
        .get(
          `${process.env.REACT_APP_TELEKARDIA_API_BASE}api/v1/chat/history/${sourceUserId}?targetChatUserId=${targetChatUserId}&size=${size}&lastMessageId=${lastMessageId}`,
          {
            headers: { Authorization: ` Bearer ${token}` }
          }
        )
        .then((res) => {
          let resultData = res.data.result
          // console.log('RESULT FROM TK', resultData)
          /**
           * Format of the message from tk is-
           * body: "dev23"
           * from: "kgdv6gvzz6fn94k9ihbzchwcx2zlpcnb_emp@us-chat-kardia-ejabberd-k8s.development.alivecor.net/aragavender865-mbp"
           * id: "1649831323386426"
           * timestamp: 1649831323386
           * to: "hlomxofsj5zltnhnu5cvchwehg668pu8_coa@us-chat-kardia-ejabberd-k8s.development.alivecor.net"
           * type: "chat"
           */
          let msgs = {}
          if (resultData.length > 0) {
            resultData.map((r) => {
              const { id, from, body, timestamp } = r
              let timeStampKey = moment(new Date(timestamp)).startOf('day')
              const currentMessage = {
                source: from,
                text: body.toString(),
                time: timestamp,
                id,
                status: 2
              }
              msgs = {
                ...msgs,
                [timeStampKey]: msgs[timeStampKey]
                  ? [...msgs[timeStampKey], currentMessage]
                  : [currentMessage]
              }
            })
          }
          setMessages(msgs)
          // console.log(msgs)
          setFetchingHistory(false)
          return
        })
        .catch((err) => {
          console.log(err)
        })
    },
    [connection]
  )

  //This is from storphe plugins documentation
  //[https://github.com/strophe/strophejs-plugin-receipts/blob/receipts/strophe.receipts.js]

  function newSendMessage(connection, msg) {
    var id = connection.getUniqueId()

    msg.tree().setAttribute('id', id)
    connection.send(msg)
    return id
  }
  /**
   * Send message
   * @param {*} msg  - content you want to send
   * @param {*} senderId - sender id
   * @param {*} recieverId - receiver Id
   */
  async function sendMessage(msg, senderId, recieverId) {
    console.log('sending msg')
    console.log(msg,senderId,recieverId)
    var m = $msg({
      to: recieverId.toLowerCase(),
      from: senderId.toLowerCase(),
      type: 'chat'
    })
      .c('body')
      .t(msg)

    //FIX: using sendMessage custom function from documentation to comment out resendMessage()
    // const msgId = connection.receipts.sendMessage(m)
    const msgId = newSendMessage(connection, m)
    // console.log(msgId)
    //update dashboard all messages
    log(recieverId + ':' + msg + ':' + Date.now(), true) //this is just for dashboard
    setMessages((prev) =>
      messageGroupByDay(prev, {
        source: senderId.toLowerCase(),
        text: msg,
        time: Date.now(),
        id: msgId,
        status: 'Sent'
      })
    )
  }
  //========================Not used now=======================

  const getPrescence = useCallback(
    (jid) => {
      var check = $pres({
        type: 'probe',
        to: jid
      })
      connection.send(check)
    },
    [connection]
  )

  return {
    status,
    messages,
    chatFirstMessage,
    typing,
    recStatus,
    currentAppointmentUser,
    sendMessage,
    getHistory,
    getPrescence,
    setUnreadMsgCount,
    fetchingHistory,
    fetchAllChatHistory,
    getHistoryFromTk
  }
}

export const ChatContext = React.createContext()

export const ChatProvider = (props) => {
  // console.log(props)
  const { userId, password } = props
  const value = useAcStrophe(userId, password)
  // console.log(value)
  return <ChatContext.Provider value={value} {...props} />
}
