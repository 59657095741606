import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Dialog, SvgIcon, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CallSharpIcon from "@material-ui/icons/CallSharp";
import {
  createSession,
  preloadScript,
  OTPublisher,
  OTSubscriber,
} from "opentok-react";
import "@opentok/client";
import "../../../Assets/styles/videocall.scss";
import MicOffOutlinedIcon from "@material-ui/icons/MicOffOutlined";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import MuiTypography from "../../Shared/Typography/MuiTypography";
import Spinner from "../../Shared/Spinner/Spinner";
import useTimer from "../../../hooks/useTimer";
import { formatTime } from "../../../Utilities/Utilities";
import EndConsultationPopUp from "./EndConsultationPopUp";
import OfflinePopup from "./OfflinePopup";
import { streamToPromise } from "../../../Utilities/Utilities";
import apiservice from "../../../api/services/apiservice.service";
import * as API from "../../../Services/API/actions";
import SockJsClient from "react-stomp";
import { useTranslation } from "react-i18next";
import { getToken } from "../../../Auth/AuthUtilities";
import PatientDeclinePopUp from "./PatientDeclinePopUp";
import PatientLeftCallPopup from "./PatientLeftCallPopup";
import clsx from "clsx";
import useJoiningTimer from "../../../hooks/useJoiningTimer";
import PatientDidntjoinCallPopup from "./PatientDidntjoinCallPopup";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: "600px",
    width: "90%",
  },
}));

const useSession = (session = {}) => {
  const { apiKey, sessionId, token } = session;
  const [streams, setStreams] = useState([]);

  const sessionHelper = useMemo(
    () =>
      sessionId &&
      sessionId !== "masked session id" &&
      createSession({
        apiKey,
        sessionId,
        token,
        onStreamsUpdated: (streams = []) => {
          setStreams([...streams]);
        },
      }),
    [apiKey, sessionId, token]
  );

  useEffect(() => {
    return () => {
      sessionId &&
        sessionId !== "masked session id" &&
        sessionHelper.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, sessionId, token]);

  return {
    sessionHelper,
    streams,
  };
};

const useNetwork = (onChange) => {
  const [status, setStatus] = useState(navigator.onLine);
  const handleChange = () => {
    if (typeof onChange === "function") {
      onChange(navigator.onLine);
    }
    setStatus(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener("online", handleChange);
    window.addEventListener("offline", handleChange);
    return () => {
      window.removeEventListener("online", handleChange);
      window.removeEventListener("offline", handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return status;
};

function VideoCall(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [patientdeclinemodal, setpatientdeclinemodal] = useState(false);
  const [offlinealertopen, setofflinealertopen] = React.useState(false);
  const [patientleftcallpopup, setpatientleftcallpopup] = useState(false);
  const [patientleftcallreason, setpatientleftcallreason] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleNetworkChange = (online) => {
    !online && setofflinealertopen(true);
  };
  const onLine = useNetwork(handleNetworkChange);
  //Generates the click handler, which returns a promise that resovles to the provided url.

  const PIP = {
    position: "absolute",
    width: "164px",
    height: "164px",
    bottom: "20px",
    left: "20px",
    zIndex: 100,
    borderWidth: "3px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49)",
    bordeRadius: "5px",
  };

  const expandmoreicon = {
    position: "absolute",
    top: "20px",
    right: "30px",
    zIndex: 100,
  };

  const [error] = React.useState(null);
  const [publishVideo, setpublishVideo] = useState(true);
  const [publishAudio, setpublishAudio] = useState(true);
  const [sesssionactive, setsesssionactive] = useState(false);
  const [callstarted, setcallstarted] = useState(false);
  const [endcallconfirmationpopup, setendcallconfirmationpopup] =
    useState(false);

  const [expandvideo, setexpandvideo] = useState(false);
  const [consultationNote, setconsultationNote] = useState("");

  useEffect(() => {
    setconsultationNote(props.consultationNote);
  }, [props.consultationNote]);

  //=======call setAppointment api after 2 secs pause=======//
  const times = useRef(null);
  const saveMyNotesAsync = (note) => {
    clearTimeout(times.current);
    times.current = setTimeout(() => {
      props.setConsultationNotevideocall(note);
    }, 2000);
  };

  const { timer, handleStart, handleReset } = useTimer(0);

  // below is to initiate function till we get sesssion id and token from api or else custom hook doesnt accept empty string or nulll value
  const [session, setsession] = React.useState({
    apiKey: 11111111,
    sessionId: "masked session id",
    token: "masked token id",
  });

  const appointmentId = props.appointmentId;
  const clinicianId = props.clinicianId;
  const teamId = props.teamId;
  const appointmentChannelTypeId = props.appointmentChannelTypeId;
  const patientName = props.patientName;

  const loadAction = useCallback(async () => {
    try {
      await streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/av/token`,
        })
      ).then((res) => {
        console.log('token success - ',res)
        setsession({
          apiKey: res.data.result.apikey,
          sessionId: res.data.result.sessionId,
          token: res.data.result.token,
        });
      }).catch(err => {
        loadAction()
      });
    } catch (e) {
      props.disableHandler(false);
      setpatientleftcallreason("doctor technical issue");
      setpatientleftcallpopup(true);
      notifyUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notifyUser =()=>{
    API.endCall(teamId,clinicianId,appointmentId).subscribe(
      (res) => {
       
      },
      (error) => {
        console.log(error);
      }
    );
  }
  // console.log("session", session);
  const { sessionHelper, streams } = useSession(session);

  // console.log("streams", streams);

  const handleEndSession = async () => {
    await sessionHelper.session.disconnect();
  };

  useEffect(() => {
    if(props.reconnectionflag === false){
      props.disableHandler(true);
    }
    loadAction();
    // window.addEventListener("beforeunload", (ev) =>
    // {
    //     ev.preventDefault();
    //     return ev.returnValue = 'Are you sure you want to close?';
    // });
    window.addEventListener("unload", handleEndSession);
    // window.addEventListener('beforeunload', handleEndSession)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionHelper.session) {
      if (props.reconnectionflag === false) {
        togglesession();
      }
      sessionHelper.session.on("streamCreated", function (event) {
        console.log("streamCreated before", event);
        handleReset();
        handleStart();
      });
      sessionHelper.session.on("streamDestroyed", function (event) {
        console.log("streamDestroyed", event);
        let version = "patientAppointment" in props.patientDetails
                    ? props.patientDetails.patientAppointment.version
                    : null;
        API.appointmentStatusCheck(teamId, clinicianId, appointmentId, version).subscribe(
          res => {
            const apStatus = res.data.result.appointment_statusid
            setpatientleftcallreason(apStatus);
            setpatientleftcallpopup(true);
          },
          (error) => props.error("Unable to process this request due to internal server error.")
        )
        // notifyUser();
        // handleReset();
      });
      sessionHelper.session.on("connectionDestroyed", function (event) {
        console.log("connectionDestroyed", event);
        // setpatientleftcallreason(event.reason);
        // setpatientleftcallpopup(true);
        // notifyUser();
        // handleReset();
      });
      sessionHelper.session.on("sessionDisconnected", function (event) {
        console.log("sessionDisconnected",event);
        handleReset();
      });
      sessionHelper.session.on("sessionReconnected", function (event) {
        console.log("sessionReconnected",event);
        // handleReset();
      });
      sessionHelper.session.on("connectionDestroyed", function (event) {
        console.log("connectionDestroyed",event);
        // handleReset();
      });
      sessionHelper.session.on("connectionCreated", function (event) {
        console.log("connectionCreated",event);
        streamToPromise(
          apiservice.postapiservice({
            baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/av/archive`,
          })
        );
      });
      sessionHelper.session.on("streamCreated", function (event) {
        console.log("streamCreated after", event);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHelper.session]);

  const { joiningtimer, handlejoiningStart, handlejoiningReset } =
    useJoiningTimer(0);

  const [patientjoiningtimeout, setpatientjoiningtimeout] = useState(false);

  useEffect(() => {
    if (sessionHelper.session && callstarted) {
      if (streams.length === 0) {
        handlejoiningStart();
      } else {
        handlejoiningReset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHelper.session, streams, callstarted]);

  useEffect(() => {
    if (joiningtimer === 300) {
      setpatientjoiningtimeout(true);
      notifyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joiningtimer]);

  useEffect(() => {
    if (sessionHelper.session) {
      return () => sessionHelper.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHelper.session]);

  const CONNECTED = {
    flex: 1,
    alignItems: "stretch",
    insertMode: "append",
    height: "inherit",
    // height: expandvideo ? "695px" :"100%",
    width: "100%",
  };

  const SUBCONNECTED = {
    //   display: "flex",
    // flexDirection: "row",
    flex: 1,
    alignItems: "stretch",
    insertMode: "append",
    height: "inherit",
    // height: expandvideo ? "695px" :"100%",
    width: "100%",
  };

  let PUBLISHER_VIDEO = { ...CONNECTED };
  if (streams.length > 0) {
    PUBLISHER_VIDEO = { ...PUBLISHER_VIDEO, ...PIP };
  }

  const togglesession = async (event) => {
    if (sesssionactive) {
      setAnchorEl(event.currentTarget);
      setendcallconfirmationpopup(true);
    } else {
      props.disableHandler(true);
      props.setpatientTechnicalIssue(false)
      handleReset();
      // handleStart();
      setsesssionactive(!sesssionactive);
      setcallstarted(!callstarted);
      await streamToPromise(
        apiservice.postapiservice({
          baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notify`,
        })
      );
      await streamToPromise(
        apiservice.postapiservice({
          baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/av/archive`,
        })
      );

      // sendMessage("calling you");
      // props.disableHandler(true);
      // handleReset();
      // // handleStart();
      // setsesssionactive(!sesssionactive);
      // setcallstarted(!callstarted);
    }
  };
  
   ////============end the call if grace period comes to end ==============////
   useEffect(() => {
     console.log(props.graceTimerEnds, sesssionactive)
    if(props.graceTimerEnds && sesssionactive){
      endsessionconfirmationclick()
    }   
  }, [props.graceTimerEnds,sesssionactive]);

  ////===========================////

  const endsessionconfirmationclick = () => {
    props.disableHandler(false);
    sessionHelper.session.signal(
      {
        data: "doctor left call",
      },
      function (error) {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
          sessionHelper.session.disconnect();
          props.setpatientTechnicalIssue(false)
          handleReset();
          setendcallconfirmationpopup(false);
          setsesssionactive(false);
          setcallstarted(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
        } else {
          console.log("signal sent for doctor left call");
          sessionHelper.session.disconnect();
          streamToPromise(
            apiservice.postapiservice({
              baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notify/endcall`,
            })
          );
          props.setpatientTechnicalIssue(false)
          handleReset();
          setendcallconfirmationpopup(false);
          setsesssionactive(false);
          setcallstarted(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
        }
      }
    );
  };

  const continuesessionconfirmationclick = () => {
    setendcallconfirmationpopup(false);
  };

  const removePopup = () => {
    setofflinealertopen(false);
  }

  const closeofflinepopup = () => {
  
    sessionHelper.session.signal(
      {
        data: "patient offline",
      },
      function (error) {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
          sessionHelper.session.disconnect();
          handleReset();
          setofflinealertopen(false);
          props.establishnewconnection(timer);
          props.setpatientTechnicalIssue(true)
          props.disableHandler(false);
        } else {
          console.log("signal sent for patient offline");
          sessionHelper.session.disconnect();
          handleReset();
          setofflinealertopen(false);
          props.establishnewconnection(timer);
          props.setpatientTechnicalIssue(true)
          props.disableHandler(false);
        }
      }
    );  
  };

  const toggleAudio = () => {
    setpublishAudio(!publishAudio);
  };

  const toggleVideo = () => {
    // OTSubscriber.subscribeToVideo(!publishVideo)
    setpublishVideo(!publishVideo);
  };

  // const togglesession = () => {
  //   setsesssionactive(!sesssionactive);
  // };

  const showstompmessage = (msg) => {
    // OTSubscriber.subscribeToVideo(!publishVideo)
    setpatientdeclinemodal(true);
    notifyUser();
  };

  const hidepatientdeclinemodal = () => {
    sessionHelper.session.signal(
      {
        data: "patient declined call",
      },
      function (error) {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
          sessionHelper.session.disconnect();
          handleReset();
          setpatientdeclinemodal(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
        } else {
          console.log("signal sent for patient declined call");
          sessionHelper.session.disconnect();
          handleReset();
          setpatientdeclinemodal(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
        }
      }
    ); 
  };

  const closePatientLeftCallPopup = () => {
    console.log('patient left call')
    setpatientleftcallpopup(false);
    props.establishnewconnection(timer);
    props.disableHandler(false);
    props.setpatientTechnicalIssue(true)
  }

  const closePatientEndedCallPopup = () => {
      sessionHelper.session.signal(
      {
        data: "patient left call",
      },
      function (error) {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
          sessionHelper.session.disconnect();
          handleReset();
          setpatientleftcallpopup(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
          props.setpatientTechnicalIssue(true)
        } else {
          console.log("signal sent for patient left call");
          sessionHelper.session.disconnect();
          handleReset();
          setpatientleftcallpopup(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
          props.setpatientTechnicalIssue(false)
        }
      }
    );
  };

  const closepatientmissedconsultaion = () => {
      sessionHelper.session.signal(
      {
        data: "patient missed consultation",
      },
      function (error) {
        if (error) {
          sessionHelper.session.disconnect();
          handleReset();
          setpatientjoiningtimeout(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
        } else {
          console.log("signal sent for patient missed consultation");
          sessionHelper.session.disconnect();
          handleReset();
          setpatientjoiningtimeout(false);
          props.establishnewconnection(timer);
          props.disableHandler(false);
        }
      }
    );
  };

  const closepatientmissedconsultaiononly = () => {
    setpatientjoiningtimeout(false);
  };

  const closepatientleftconsultaiononly = () => {
    setpatientleftcallpopup(false)
  }

  const closePatientLeftCallPopupProviderside = () => {
    setpatientleftcallpopup(false);
    handleReset();
    props.establishnewconnection(timer);
    props.disableHandler(false);
  };

  const closePatientLeftCallPopupProvidersidedismiss = () => {
    setpatientleftcallpopup(false);
    handleReset();
    props.establishnewconnection(timer);
    props.disableHandler(false);
  };

  const customHeaders = {
    Authorization: `Bearer ${getToken()}`,
  };

  const toggleexpansion = () => {
    setexpandvideo(!expandvideo);
  };

  const onPublish = () => {
    console.log("Publish Success");
  };
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="left" ref={ref} {...props} />;
  // });

  return (
    <>
      {expandvideo ? (
        <Dialog
          fullWidth={true}
          maxWidth={"xl"}
          classes={{ paper: classes.dialogPaper }}
          // TransitionComponent={Transition}
          // onClose={props.handleClose}
          aria-labelledby="simple-dialog-title"
          open={expandvideo}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <div className="telekardia">
            <div className="row no-gutters">
              <div className="col-9 bg_white px-0 pt-0 mb-md-3 mb-xl-3">
                <div className="d-flex flex-row align-items-center chat_head pb-2 px-3 panel_head">
                  {callstarted && (
                    <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
                      <div className="d-flex flex-row weight_600">
                        {streams && streams.length > 0 ? (
                          "Call in progress..."
                        ) : (
                          <MuiTypography
                            fontSize="16px"
                            fontFamily="Work Sans"
                            lineHeight="20px"
                            letterSpacing="0.25px"
                            fontWeight={600}
                            color="#FFFFFF"
                          >
                            Calling
                          </MuiTypography>
                        )}
                        {streams && streams.length > 0 ? (
                          ""
                        ) : (
                          <span className="pl-2" style={{ maxWidth: "150px" }}>
                            <MuiTypography
                              fontSize="16px"
                              fontFamily="Work Sans"
                              lineHeight="20px"
                              letterSpacing="0.25px"
                              fontWeight={600}
                              color="#FFFFFF"
                            >
                              {patientName.slice(0, 11)}...
                            </MuiTypography>
                          </span>
                        )}
                      </div>
                      <span className="font-weight-normal">
                        {" "}
                        {streams && streams.length > 0
                          ? formatTime(timer)
                          : "    "}
                      </span>
                    </div>
                  )}

                  {appointmentChannelTypeId && appointmentChannelTypeId === 3 && (
                    <button
                      type="button"
                      className="btn text-uppercase h40 btn-outline-white min-width127 ml-auto position-relative px-3 d-flex justify-content-between align-items-center"
                      onClick={toggleVideo}
                    >
                      {publishVideo ? (
                        <>
                          <SvgIcon viewBox="0 0 18 12" style={{ fontSize: 20 }}>
                            <path
                              d="M14 4.5V1C14 0.734783 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734783 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z"
                              fill="#FFFFFF"
                            />
                          </SvgIcon>
                          {t("Video")}
                        </>
                      ) : (
                        <>
                          <SvgIcon viewBox="0 0 19 19" style={{ fontSize: 20 }}>
                            <path
                              d="M1.27 0L0 1.27L2.73 4H2C1.73478 4 1.48043 4.10536 1.29289 4.29289C1.10536 4.48043 1 4.73478 1 5V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H14C14.2 16 14.39 15.92 14.54 15.82L17.73 19L19 17.73L1.27 0ZM19 4.5L15 8.5V5C15 4.73478 14.8946 4.48043 14.7071 4.29289C14.5196 4.10536 14.2652 4 14 4H7.82L19 15.18V4.5Z"
                              fill="#FFFFFF"
                            />
                          </SvgIcon>
                          {t("Video")}
                        </>
                      )}
                    </button>
                  )}
                  <button
                    type="button"
                    className={
                      appointmentChannelTypeId && appointmentChannelTypeId === 3
                        ? "btn text-uppercase h40 btn-outline-white min-width127 ml-2 position-relative px-3 d-flex justify-content-between align-items-center"
                        : "btn text-uppercase h40 btn-outline-white min-width127 ml-auto position-relative px-3 d-flex justify-content-between align-items-center"
                    }
                    onClick={toggleAudio}
                  >
                    {publishAudio ? (
                      <>
                        <SvgIcon viewBox="0 0 14 19" style={{ fontSize: 20 }}>
                          <path
                            d="M7 0C7.79565 0 8.55871 0.316071 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3V9C10 9.79565 9.68393 10.5587 9.12132 11.1213C8.55871 11.6839 7.79565 12 7 12C6.20435 12 5.44129 11.6839 4.87868 11.1213C4.31607 10.5587 4 9.79565 4 9V3C4 2.20435 4.31607 1.44129 4.87868 0.87868C5.44129 0.316071 6.20435 0 7 0ZM14 9C14 12.53 11.39 15.44 8 15.93V19H6V15.93C2.61 15.44 0 12.53 0 9H2C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9H14Z"
                            fill="#FFFFFF"
                          />
                        </SvgIcon>
                        {t("Mute")}
                      </>
                    ) : (
                      <>
                        <MicOffOutlinedIcon />
                        {t("Unmute")}
                      </>
                    )}
                  </button>

                  <button
                    type="button"
                    className={
                      callstarted
                        ? "btn text-uppercase h40 bg_red_darker min-width163 ml-3 position-relative"
                        : "btn text-uppercase h40 btn_green min-width163 ml-3 position-relative"
                    }
                    onClick={togglesession}
                  >
                    {callstarted ? (
                      <>
                        <SvgIcon
                          viewBox={
                            appointmentChannelTypeId &&
                            appointmentChannelTypeId === 3
                              ? "0 0 18 12"
                              : "0 0 24 24"
                          }
                          style={{ fontSize: 20 }}
                        >
                          <path
                            d={
                              appointmentChannelTypeId &&
                              appointmentChannelTypeId === 3
                                ? "M14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z"
                                : streams && streams.length > 0
                                ? "M3.68 16.07l3.92-3.11V9.59c2.85-.93 5.94-.93 8.8 0v3.38l3.91 3.1L24 12.39c-6.41-7.19-17.59-7.19-24 0l3.68 3.68z"
                                : "M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                            }
                            fill="#FFFFFF"
                          />
                        </SvgIcon>
                        <span className="d-inline-block ml-5">
                          {t("END CALL")}
                        </span>
                      </>
                    ) : (
                      <>
                        <SvgIcon
                          viewBox={
                            appointmentChannelTypeId &&
                            appointmentChannelTypeId === 3
                              ? "0 0 18 12"
                              : "0 0 24 24"
                          }
                          style={{ fontSize: 20 }}
                        >
                          <path
                            d={
                              appointmentChannelTypeId &&
                              appointmentChannelTypeId === 3
                                ? "M14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z"
                                : "M21 15.46l-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53L8.54 3H3.03C2.45 13.18 10.82 21.55 21 20.97v-5.51z"
                            }
                            fill="#FFFFFF"
                          />
                        </SvgIcon>
                        {t("START CALL")}
                      </>
                    )}
                  </button>
                </div>
                <div className="videoclls d-flex flex-column align-items-center justify-content-center">
                  {callstarted ? (
                    <>
                      {sesssionactive ? (
                        <div className="col-12 bg_white heigh500video p-3 m-0">
                          {error ? (
                            <div className="error">
                              <strong>Error:</strong>
                            </div>
                          ) : null}

                          <>
                            {/* MessageHistory: {messageHistory.join(', ')} */}
                            <OTPublisher
                              style={PUBLISHER_VIDEO}
                              properties={{
                                publishAudio: publishAudio,
                                publishVideo:
                                  publishVideo &&
                                  appointmentChannelTypeId &&
                                  appointmentChannelTypeId === 3,
                                showControls: false,
                                width: "100%",
                                height: "100%",
                              }}
                              session={sessionHelper.session}
                              onPublish={onPublish}
                            />
                            {
                              streams &&
                                streams.length > 0 &&
                                streams.map((stream) => {
                                  return (
                                    <OTSubscriber
                                      style={SUBCONNECTED}
                                      key={stream.id}
                                      session={sessionHelper.session}
                                      stream={stream}
                                      properties={{
                                        publishAudio: publishAudio,
                                        publishVideo: publishVideo,
                                        // videoSource: 'screen',
                                        showControls: false,
                                        // eventHandlers:{eventHandlers},
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  );
                                })
                              // : (
                              //   <div className="user_img"></div>
                              // )
                            }
                            <div style={expandmoreicon}>
                              <IconButton
                                aria-label="upload picture"
                                component="span"
                                onClick={toggleexpansion}
                              >
                                <SvgIcon
                                  viewBox="0 0 22 22"
                                  style={{ fontSize: 20 }}
                                >
                                  <path
                                    d="M19.8922 0.200195L14.4922 5.6002V1.2922H12.0922V9.6922H20.4922V7.2922H16.1842L21.5842 1.8922L19.8922 0.200195ZM1.2922 12.0922V14.4922H5.6002L0.200195 19.8922L1.8922 21.5842L7.2922 16.1842V20.4922H9.6922V12.0922H1.2922Z"
                                    fill="#2D9F86"
                                  />
                                </SvgIcon>
                              </IconButton>
                            </div>
                          </>
                        </div>
                      ) : (
                        <>
                          <div className="col-12 mb-3 bg_white height720">
                            <div className="user_img"></div>
                            <div className="position-absolute fixed-bottom text-center py-3">
                              <p className="font_white">
                                {t("Call discontinued")}
                              </p>
                              <button
                                type="button"
                                className="btn text-uppercase h40 ml-auto btn_green min-width127"
                                onClick={togglesession}
                              >
                                <CallSharpIcon className="f30" />{" "}
                                {"Re-START CALL"}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <AccountCircleRoundedIcon style={{ fontSize: 70 }} />
                      <MuiTypography
                        fontSize="24px"
                        fontFamily="Work Sans"
                        lineHeight="40px"
                        letterSpacing="0.25px"
                        fontWeight={700}
                        color="#142A39"
                      >
                        {t("Choose your video and audio settings")}
                      </MuiTypography>
                    </>
                  )}
                  {
                    <EndConsultationPopUp
                      open={endcallconfirmationpopup}
                      anchorEl={anchorEl}
                      continueSession={continuesessionconfirmationclick}
                      endSession={endsessionconfirmationclick}
                    />
                  }
                </div>
              </div>
              <div className="col-3 bg_white px-3 pt-3 mb-md-3 mb-xl-3">
                <h5 className="head">{t("My Notes")}</h5>
                <textarea
                  onChange={(e) => {
                    setconsultationNote(e.target.value)
                    saveMyNotesAsync(e.target.value)
                  }}
                  className="form-control bg_grey height_600_mynotes_expandvideo mt-3 border-0"
                  placeholder="Write your personal notes here. They will not be shared with the patient."
                  value={consultationNote}
                />
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <div className="telekardia">
          <div className="row no-gutters">
            <div className="col-12 bg_white px-0 pt-0 mb-md-3 mb-xl-3">
              <div className="d-flex flex-row align-items-center chat_head pb-2 px-3 panel_head">
                {callstarted ? (
                  <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
                    <div className="d-flex flex-row weight_600">
                      {(streams && streams.length > 0) ||
                      patientleftcallpopup ? (
                        "Call in progress..."
                      ) : (
                        <MuiTypography
                          fontSize="16px"
                          fontFamily="Work Sans"
                          lineHeight="20px"
                          letterSpacing="0.25px"
                          fontWeight={600}
                          color="#FFFFFF"
                        >
                          Calling
                        </MuiTypography>
                      )}
                      {(streams && streams.length > 0) ||
                      patientleftcallpopup ? (
                        ""
                      ) : (
                        <span className="pl-2" style={{ maxWidth: "150px" }}>
                          <MuiTypography
                            fontSize="16px"
                            fontFamily="Work Sans"
                            lineHeight="20px"
                            letterSpacing="0.25px"
                            fontWeight={600}
                            color="#FFFFFF"
                          >
                            {patientName.slice(0, 11)}...
                          </MuiTypography>
                        </span>
                      )}
                    </div>
                    <span className="font-weight-normal">
                      {" "}
                      {streams && streams.length > 0
                        ? formatTime(timer)
                        : "    "}
                    </span>
                  </div>
                ) : (
                  props.reconnectionflag === true && (
                    <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
                      <div className="d-flex flex-row weight_600">
                        Call ended
                      </div>
                      <span className="font-weight-normal">
                        {" "}
                        Total duration : {formatTime(props.finalcounttimer)}
                      </span>
                    </div>
                  )
                )}

                {appointmentChannelTypeId &&
                  appointmentChannelTypeId === 3 &&
                  callstarted && (
                    // <CustomButton
                    // className="btn_teal_two min-width72 ml-auto"
                    //   // className={
                    //   //   appointmentChannelTypeId && appointmentChannelTypeId === 3
                    //   //     ? "btn_teal_two min-width72 ml-2"
                    //   //     : "btn_teal_two min-width72 ml-auto"
                    //   // }
                    //   onClick={toggleVideo}
                    // >
                    <button
                      type="button"
                      className="btn text-uppercase h40 btn-outline-white min-width127 ml-auto position-relative px-3 d-flex justify-content-between align-items-center"
                      onClick={toggleVideo}
                    >
                      {publishVideo ? (
                        <>
                          <SvgIcon viewBox="0 0 18 12" style={{ fontSize: 20 }}>
                            <path
                              d="M14 4.5V1C14 0.734783 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734783 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z"
                              fill="#FFFFFF"
                            />
                          </SvgIcon>
                          <span className="d-inline-block ml-2">
                            {t("Video")}
                          </span>
                        </>
                      ) : (
                        <>
                          <SvgIcon viewBox="0 0 19 19" style={{ fontSize: 20 }}>
                            <path
                              d="M1.27 0L0 1.27L2.73 4H2C1.73478 4 1.48043 4.10536 1.29289 4.29289C1.10536 4.48043 1 4.73478 1 5V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H14C14.2 16 14.39 15.92 14.54 15.82L17.73 19L19 17.73L1.27 0ZM19 4.5L15 8.5V5C15 4.73478 14.8946 4.48043 14.7071 4.29289C14.5196 4.10536 14.2652 4 14 4H7.82L19 15.18V4.5Z"
                              fill="#FFFFFF"
                            />
                          </SvgIcon>
                          {t("Video")}
                        </>
                      )}
                    </button>
                    // </CustomButton>
                  )}
                {/* <CustomButton
                  // className="btn_teal_two min-width72 ml-2"
                  className={
                    appointmentChannelTypeId && appointmentChannelTypeId === 3
                      ? "btn_teal_two min-width72 ml-2"
                      : "btn_teal_two min-width72 ml-auto"
                  }
                  onClick={toggleAudio}
                > */}
                {callstarted && (
                  <button
                    type="button"
                    className={
                      appointmentChannelTypeId && appointmentChannelTypeId === 3
                        ? "btn text-uppercase h40 btn-outline-white min-width127 ml-2 position-relative px-3 d-flex justify-content-between align-items-center"
                        : "btn text-uppercase h40 btn-outline-white min-width127 ml-auto position-relative px-3 d-flex justify-content-between align-items-center"
                    }
                    onClick={toggleAudio}
                  >
                    {publishAudio ? (
                      <>
                        <SvgIcon viewBox="0 0 14 19" style={{ fontSize: 20 }}>
                          <path
                            d="M7 0C7.79565 0 8.55871 0.316071 9.12132 0.87868C9.68393 1.44129 10 2.20435 10 3V9C10 9.79565 9.68393 10.5587 9.12132 11.1213C8.55871 11.6839 7.79565 12 7 12C6.20435 12 5.44129 11.6839 4.87868 11.1213C4.31607 10.5587 4 9.79565 4 9V3C4 2.20435 4.31607 1.44129 4.87868 0.87868C5.44129 0.316071 6.20435 0 7 0ZM14 9C14 12.53 11.39 15.44 8 15.93V19H6V15.93C2.61 15.44 0 12.53 0 9H2C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9H14Z"
                            fill="#FFFFFF"
                          />
                        </SvgIcon>
                        {t("Mute")}
                      </>
                    ) : (
                      <>
                        <MicOffOutlinedIcon />
                        {t("Unmute")}
                      </>
                    )}
                  </button>
                )}
                {sesssionactive && (

                <button
                  type="button"
                  disabled={sessionHelper.session === undefined}
                  className={clsx(
                    props.reconnectionflag === false || callstarted
                      ? "btn text-uppercase h40 bg_red_darker min-width163"
                      : props.reconnectionflag === false || !callstarted
                      ? "btn text-uppercase h40 btn_white_fill min-width163"
                      : "btn text-uppercase h40 btn_green min-width163",
                    callstarted
                      ? "ml-3 position-relative"
                      : "ml-auto position-relative"
                  )}
                  onClick={togglesession}
                >
                  {props.reconnectionflag === false || callstarted ? (
                    <>
                      <SvgIcon
                        viewBox={
                          appointmentChannelTypeId &&
                          appointmentChannelTypeId === 3
                            ? "0 0 18 12"
                            : streams && streams.length > 0
                            ? "0 0 24 24"
                            : "0 0 18 18"
                        }
                        style={{ fontSize: 20 }}
                      >
                        <path
                          d={
                            appointmentChannelTypeId &&
                            appointmentChannelTypeId === 3
                              ? "M14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z"
                              : streams && streams.length > 0
                              ? "M3.68 16.07l3.92-3.11V9.59c2.85-.93 5.94-.93 8.8 0v3.38l3.91 3.1L24 12.39c-6.41-7.19-17.59-7.19-24 0l3.68 3.68z"
                              : "M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                          }
                          fill="#FFFFFF"
                        />
                      </SvgIcon>
                      <span className="d-inline-block ml-5">
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="16.71px"
                          letterSpacing="0.25px"
                          fontWeight={700}
                          color="#FFFFFF"
                        >
                          {t("END CALL")}
                        </MuiTypography>
                      </span>
                    </>
                  ) : (
                    <>
                      <SvgIcon
                        // viewBox={
                        //   appointmentChannelTypeId &&
                        //   appointmentChannelTypeId === 3
                        //     ? "0 0 18 12"
                        //     : "0 0 24 24"
                        // }
                        viewBox="0 0 18 19"
                        style={{ fontSize: 19 }}
                      >
                        <path
                          // d={
                          //   appointmentChannelTypeId &&
                          //   appointmentChannelTypeId === 3
                          //     ? "M14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5Z"
                          //     : "M21 15.46l-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53L8.54 3H3.03C2.45 13.18 10.82 21.55 21 20.97v-5.51z"
                          // }
                          d="M18 3.5V8.5H16.5V5H10.87L13.3 7.43L12.24 8.5L8 4.25L12.24 0L13.3 1.07L10.87 3.5H18ZM5.82 6.08C6.08 5.82 6.17 5.43 6.06 5.08C5.69 3.92 5.5 2.72 5.5 1.5C5.5 1.23478 5.39464 0.98043 5.20711 0.792893C5.01957 0.605357 4.76522 0.5 4.5 0.5H1C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5C0 6.00868 1.79107 10.3327 4.97918 13.5208C8.1673 16.7089 12.4913 18.5 17 18.5C17.2652 18.5 17.5196 18.3946 17.7071 18.2071C17.8946 18.0196 18 17.7652 18 17.5V14C18 13.7348 17.8946 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13C15.79 13 14.58 12.81 13.43 12.43C13.08 12.32 12.69 12.41 12.43 12.67L10.23 14.87C7.39 13.42 5.09 11.12 3.64 8.28L5.82 6.08Z"
                          fill="#142A39"
                        />
                      </SvgIcon>
                      <span className="d-inline-block ml-5">
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="16.71px"
                          letterSpacing="0.25px"
                          fontWeight={700}
                          color={
                            props.reconnectionflag === false
                              ? "#FFFFFF"
                              : "#142A39"
                          }
                        >
                          {props.reconnectionflag === false
                            ? t("START CALL")
                            : t("Redial")}
                        </MuiTypography>
                      </span>
                    </>
                  )}
                </button>
                )}

              {/* rejoin button if call disconnected due to sone technical issues */}
                {props.patientTechnicalIssue && (
                  <>
                  <button
                    type="button"
                    disabled={!onLine}
                    className={"btn text-uppercase h40 btn_white_fill min-width163 ml-auto position-relative"}
                    onClick={togglesession}
                  >
                      <SvgIcon
                        viewBox="0 0 18 19"
                        style={{ fontSize: 19 }}
                      >
                        <path
                          d="M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                          fill="#142A39"
                        />
                      </SvgIcon>
                      <span className="d-inline-block ml-5">
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="16.71px"
                          letterSpacing="0.25px"
                          fontWeight={700}
                          color={"#142A39"}
                        >
                          {t("Rejoin")}
                        </MuiTypography>
                      </span>
                  </button>
                  <button 
                    type="button"
                    style={{marginLeft: '10px'}}
                    // disabled={props.reviewSession || (!callstarted && props.endTimeFlag)}
                    className='btn text-uppercase h40 btn_green min-width72'
                    onClick={() => props.choosePhoneCall(true)}>
                    <SvgIcon viewBox="0 0 18 19" style={{ fontSize: 19 }}>
                      <path
                      d={
                        'M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z'
                      }
                      fill="#FFFFFF"
                      />
                    </SvgIcon> PHONE CALL             
                  </button>
                  </>
                )}
                </div>
              <div
                className={
                  // publishVideo &&
                  appointmentChannelTypeId && appointmentChannelTypeId === 3
                    ? "videoclls d-flex flex-column align-items-center justify-content-center"
                    : "heightaudiozero d-flex flex-column align-items-center justify-content-center"
                }
              >
                {callstarted ? (
                  <>
                    {sesssionactive ? (
                      <div className="col-12 bg_white height460 p-3 m-0">
                        {error ? (
                          <div className="error">
                            <strong>Error:</strong>
                          </div>
                        ) : null}

                        <>
                          <OTPublisher
                            style={PUBLISHER_VIDEO}
                            properties={{
                              publishAudio: publishAudio,
                              publishVideo:
                                publishVideo &&
                                appointmentChannelTypeId &&
                                appointmentChannelTypeId === 3,
                              videoSource:
                                publishVideo &&
                                appointmentChannelTypeId &&
                                appointmentChannelTypeId === 2
                                  ? null
                                  : true,
                              showControls: false,
                              width: "100%",
                              height: "100%",
                            }}
                            session={sessionHelper.session}
                          />
                          {
                            streams &&
                              streams.length > 0 &&
                              streams.map((stream) => {
                                return (
                                  <OTSubscriber
                                    style={SUBCONNECTED}
                                    key={stream.id}
                                    session={sessionHelper.session}
                                    stream={stream}
                                    properties={{
                                      publishAudio: publishAudio,
                                      publishVideo: publishVideo,
                                      videoSource:
                                        publishVideo &&
                                        appointmentChannelTypeId &&
                                        appointmentChannelTypeId === 2
                                          ? null
                                          : true,
                                      // videoSource: 'screen',
                                      showControls: false,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                );
                              })
                            // : (
                            //   <div className="user_img"></div>
                            // )
                          }
                          <div style={expandmoreicon}>
                            <IconButton
                              aria-label="upload picture"
                              component="span"
                              onClick={toggleexpansion}
                            >
                              <SvgIcon
                                viewBox="0 0 22 22"
                                style={{ fontSize: 20 }}
                              >
                                <path
                                  d="M8.6002 21.8002V19.4002H4.2922L9.6922 14.0002L8.0002 12.3082L2.6002 17.7082V13.4002H0.200195V21.8002H8.6002ZM14.0002 9.6922L19.4002 4.2922V8.6002H21.8002V0.200195H13.4002V2.6002H17.7082L12.3082 8.0002L14.0002 9.6922Z"
                                  fill="#2D9F86"
                                />
                              </SvgIcon>
                            </IconButton>
                          </div>
                        </>
                      </div>
                    ) : (
                      <>
                        <div className="col-12 mb-3 bg_white height460">
                          <div className="user_img"></div>
                          <div className="position-absolute fixed-bottom text-center py-3">
                            <p className="font_white">
                              {t("Call discontinued")}
                            </p>
                            <button
                              type="button"
                              className="btn text-uppercase h40 ml-auto btn_green min-width127"
                              onClick={togglesession}
                            >
                              <CallSharpIcon className="f30" />{" "}
                              {"Re-START CALL"}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div>
                    {props.reconnectionflag === false && <Spinner></Spinner>}

                    {/* <AccountCircleRoundedIcon style={{ fontSize: 70 }} />
                    <MuiTypography
                      fontSize="24px"
                      fontFamily="Work Sans"
                      lineHeight="40px"
                      letterSpacing="0.25px"
                      fontWeight={700}
                      color="#142A39"
                    >
                      {t("Choose your video and audio settings")}
                    </MuiTypography> */}
                  </div>
                )}
                {
                  <EndConsultationPopUp
                    open={endcallconfirmationpopup}
                    anchorEl={anchorEl}
                    continueSession={continuesessionconfirmationclick}
                    endSession={endsessionconfirmationclick}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <OfflinePopup
          open={offlinealertopen}
          closeofflinepopup={closeofflinepopup}
          removePopup={removePopup}
          onLine={onLine}
        />
      }
      {
        <PatientDeclinePopUp
          open={patientdeclinemodal}
          closeofflinepopup={hidepatientdeclinemodal}
        />
      }
      {
        <PatientLeftCallPopup
          open={patientleftcallpopup}
          patientleftcallreason={patientleftcallreason}
          closePatientEndedCallPopup={closePatientEndedCallPopup}
          closePatientLeftCallPopup={closePatientLeftCallPopup}
          closePatientLeftCallPopupProviderside={
            closePatientLeftCallPopupProviderside
          }
          closePatientLeftCallPopupProvidersidedismiss={
            closePatientLeftCallPopupProvidersidedismiss
          }
          closepatientleftconsultaiononly={closepatientleftconsultaiononly}
        />
      }
      <PatientDidntjoinCallPopup
        open={patientjoiningtimeout}
        closepatientmissedconsultaion={closepatientmissedconsultaion}
        closepatientmissedconsultaiononly={closepatientmissedconsultaiononly}
      />
      <SockJsClient
        headers={customHeaders}
        url={`${process.env.REACT_APP_TELEKARDIA_API_BASE}teleconnect-ws`}
        topics={[`/clinician/${clinicianId}/web/notification`]}
        //  onConnect={onConnect}
        onMessage={(msg: any) => showstompmessage(msg)}
      />
    </>
  );
}

export default preloadScript(VideoCall);
