/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as API from '../../Services/API/actions';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../Store/actions/index';
import swal from 'sweetalert';
import moment from 'moment';
import * as Constants from '../../Utilities/Constants';
import { getAge, getTimezoneName, streamToPromise } from '../../Utilities/Utilities';
import AppointmentStatusModal from './AppointmentStatus/AppointmentStatus';
import Alert from '../Shared/Alert/Alerts';
import _get from 'lodash.get';
import EkgPreviewModal from '../Shared/EKG-preview/ekgPreview';
import Modal from '../Appointments/Calendar/AppointmentDetailsModal';
import AppointmentContent from '../AppointmentDetails/appointmentContent';
import '../../Assets/styles/bootstrap.scss';
import ReAssignAppointment from './ReAssignAppointment';
import { useTranslation } from 'react-i18next';
// import { Chat } from '../../providers';
import { ChatContext } from '@alivecor/strophe2.0';
import { GoAPI } from '../../Axios/axios';
import apiserviceService from '../../api/services/apiservice.service';
import { getToken } from '../../Auth/AuthUtilities';
import axios from "axios";

const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';

const withStartConsultation = (WrapperComponent) => {
  return function Comp(props) {
    const { t } = useTranslation();
    const { setCurrentAppointmentUser } = useContext(ChatContext);
    const [showModal, setModal] = useState(false);
    const [startConsultationModal, setConsulationModal] = useState(false);
    const [patientDetails, setPatientDetails] = useState({});
    const [recording, setRecording] = useState({});
    const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
    const dispatch = useDispatch();
    const clinicianId = useSelector((state) => state.user.profile.id);
    const teamId = useSelector((state) => state.user.team.id);
    const [reassignmodalOpen, setreassignmodal] = useState(false);
    const [consultationloader, setconsulationloader] = useState(false);
    const [heartConditions, setheartConditions] = React.useState([]);
    const [rejoinCallFlag, setrejoinCallFlag] = useState(false)
    const [inProgressCall, setinProgressCall] = useState(false)


    ///========= fetching ekg data when clicked from banner =========//
      const loadAction = useCallback(async () => {    
        let offsetInMinutes = new Date().getTimezoneOffset();
        const appointmentRequest = streamToPromise(
          apiserviceService.getapiservice({
            baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${patientDetailsntwrk.appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
          })
        );
        const appointmentdetailsdata = await appointmentRequest;
        setPatientDetails(appointmentdetailsdata.data);
      }, []);
    
      useEffect(() => {
        loadAction();
      }, [patientDetailsntwrk.appointmentId]);
    
      const loadEkgAction = useCallback((recordingid) => {    
        let samps = GoAPI.get(`/i/v1/recordings/${recordingid}/samples`, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
        let recs = GoAPI.get(`/i/v1/recordings/${recordingid}`, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
    
        axios
          .all([recs, samps])
          .then(
            axios.spread((...responses) => {
              setRecording({ ...responses[0].data, ...responses[1].data });
              // setloading(false);
            })
          )
          .catch((error) => {
            // setAlertOn(true);
            setRecording({});
            // setloading(false);
          });
      }, []);
    
      useEffect(() => {
        if (
          Object.keys(patientDetails).length > 0 &&
          patientDetails.ekgResults &&
          patientDetails.ekgResults.length > 0 &&
          patientDetails.ekgResults[0].ekgRecordId != ''
        ) {
          loadEkgAction(patientDetails.ekgResults[0].ekgRecordId);
        }else{
          setRecording({})
        }
      }, [patientDetails]);

  
  ///=================== end ==============//

    useEffect(() => {
      if(patientDetailsntwrk?.patientAppointment?.appointmentStatusId === 7){
        setrejoinCallFlag(true)
      }else if(patientDetailsntwrk?.patientAppointment?.appointmentStatusId === 3){
        setinProgressCall(true)
      }
      if (patientDetailsntwrk.patient) {
        patientDetailsntwrk.patient['age'] = getAge(patientDetailsntwrk.patient.dob);
        if (patientDetailsntwrk.patient['height'])
          patientDetailsntwrk.patient['height'] = parseFloat(
            patientDetailsntwrk.patient['height'],
          ).toFixed(2);
        if (patientDetailsntwrk.patient['weight'])
          patientDetailsntwrk.patient['weight'] = parseFloat(
            patientDetailsntwrk.patient['weight'],
          ).toFixed(2);
      }
      if (patientDetailsntwrk.patientAppointment) {
        patientDetailsntwrk.patientAppointment['rangestring'] =
          moment(patientDetailsntwrk.patientAppointment.scheduledStartTime).format('hh:mm A') +
          ' to ' +
          moment(patientDetailsntwrk.patientAppointment.scheduledEndTime).format('hh:mm A') +
          ' (' +
          (
            (new Date(patientDetailsntwrk.patientAppointment.scheduledEndTime).getTime() -
              new Date(patientDetailsntwrk.patientAppointment.scheduledStartTime).getTime()) /
            60000
          ).toString() +
          ' Minutes)';
      }
      setPatientDetails(patientDetailsntwrk);
      // setRecording(props.recording);
    }, [patientDetailsntwrk, props.recording]);
    useEffect(() => {
      let subscription;
      if (props.id) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        subscription = API.heartConditions().subscribe(
          (res) => {
            if (res.data.result != null) {
              let conditions = [
                {
                  id: 0,
                  heartCondition: 'Select EKG Interpretation',
                  heartConditionAdditionalText: '',
                },
                ...res.data.result,
              ];
              setheartConditions(conditions);
            }
            let heartConditions = res.data.result.sort(function (a, b) {
              return a.id - b.id;
            });
            // setoverReadReport(heartConditions[0].heartConditionAdditionalText?res.data.result[0].heartConditionAdditionalText:'');
            // setlastoverheadreportnote(heartConditions[0].heartConditionAdditionalText?res.data.result[0].heartConditionAdditionalText:'');
          },
          (error) => {
            console.log(error);
          },
        );
      }
      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }, [props.id]);

    useEffect(() => {
      let subscription;
      if (props.id) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        subscription = API.getAppointmentNotes(teamId, clinicianId, props.id).subscribe(
          (res) => {
            if (res.data.appointmentNote) {
              setconsultationNote(decodeURI(res.data.appointmentNote));
              setlastconsultationNote(decodeURI(res.data.appointmentNote));
            } else setconsultationNote('');
          },
          // eslint-disable-next-line no-console
          (error) => console.log(error),
        );
      }

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }, [props.id]);

    const handleClose = () => {
      setModal(false);
    };

    const handleConsultationClose = () => {
      console.log('with start consultation...');
      setconsulationloader(true);
    if(Object.keys(patientDetails).length > 0 &&
    patientDetails.ekgResults &&
    patientDetails.ekgResults.length > 0 &&
    patientDetails.ekgResults[0].ekgRecordId != ''){
      if (
        (overReadReport.length > 0 && consultationSummary.length > 0) || 
        (overReadReport.length > 0 && seriespattern!=="Completed") ||
        (overReadReport.length>0 &&(_get(patientDetails,"patientAppointment.closedReasonType") ===2 || 
        _get(patientDetails,"patientAppointment.closedReasonType") ===3))) {
          console.log('close session if');
        let body = {
          appointmentStatus:
            seriespattern === "Completed"
              ? Constants.AppointmentStatusTypes.Completed
              : Constants.AppointmentStatusTypes.closed,
          consultationSummaryNotes: encodeURI(consultationSummary),
          followup: period,
          heartConditionId: id,
          overReadReportNotes: encodeURI(overReadReport),
          patientId: _get(patientDetails, "patient.patientId"),
          treatmentPlan: encodeURI(targetPlan),
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone:getTimezoneName(),
        };
        API.postConsultation(teamId,clinicianId, props.id, body).subscribe(
          (res) => {
            if("detailed_error" in res.data){
              props.error(res.data["detailed_error"]);
              setconsulationloader(false);
            }
            else{
              setconsulationloader(false);
              swal({
                closeOnClickOutside: false,
                icon: "success",
                title:"Consultation Completed" ,
                text: "Your consultation has been successfully completed!",
                className:"telekardia",
                buttons: {
                  confirm: {
                    text: "OKAY",
                    visible: true,
                    className: "swal_ok swal_green",
                    closeModal: true,
                  },
                },
              }).then((value) => {
                if (value) {
                  setConsulationModal(false);
                  let appointmentqueryParams = {
                    startDateTime: moment(moment().startOf("day")._d).format(pattern),
                    endDateTime: moment(moment().endOf("day")._d).format(pattern),
                    offsetInMinutes: new Date().getTimezoneOffset(),
                    timeZone:getTimezoneName(),
                    appointmentStatus: "1,2,3,6,7,9,12",
                  };
                  let queryParams = {
                    startDate: moment(moment().startOf("day")._d).format(pattern),
                    endDate: moment(moment().endOf("day")._d).format(pattern),
                    offsetInMinutes: new Date().getTimezoneOffset(),
                    timeZone:getTimezoneName(),
                  };
                  dispatch(
                    actions.getAppointments(teamId,clinicianId, appointmentqueryParams)
                  );
                  dispatch(
                    actions.getCliniciansAppointments(
                      teamId,
                      clinicianId,
                      queryParams
                    ));
                  props.loader();
                }
              });
            }
          
          },
          (error) => {
            setconsulationloader(false);
            props.error("Unable to process this request due to internal server error.")
          }
        );
       
      } else {
        setconsulationloader(false);
        seterrorAlert(
          <Alert
            date={new Date()}
            message={seriespattern!=="Completed" || _get(patientDetails,"patientAppointment.closedReasonType")===2 
            || _get(patientDetails,"patientAppointment.closedReasonType")===3 || id == 0
            ?"Please select the EKG interpretation to proceed to the next step.":"Please enter the EKG Summary and Patient Note to proceed to the next step."}
          /> 
        );
        if(id == 0 )
          setIsError(true);
      }
    }else {
      console.log('close session else');
      let body = {
        appointmentStatus:
          seriespattern === "Completed"
            ? Constants.AppointmentStatusTypes.Completed
            : Constants.AppointmentStatusTypes.closed,
        consultationSummaryNotes: encodeURI(consultationSummary),
        followup: period,
        heartConditionId: null,
        overReadReportNotes: encodeURI(overReadReport),
        patientId: _get(patientDetails, "patient.patientId"),
        treatmentPlan: encodeURI(targetPlan),
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
      };
      API.postConsultation(teamId,clinicianId, props.id, body).subscribe(
        (res) => {
          if("detailed_error" in res.data){
            props.error(res.data["detailed_error"]);
            setconsulationloader(false);
          }
          else{
            setconsulationloader(false);
            swal({
              closeOnClickOutside: false,
              icon: "success",
              title:"Consultation Completed" ,
              text: "Your consultation has been successfully completed!",
              className:"telekardia",
              buttons: {
                confirm: {
                  text: "OKAY",
                  visible: true,
                  className: "swal_ok swal_green",
                  closeModal: true,
                },
              },
            }).then((value) => {
              if (value) {
                setConsulationModal(false);
                let appointmentqueryParams = {
                  startDateTime: moment(moment().startOf("day")._d).format(pattern),
                  endDateTime: moment(moment().endOf("day")._d).format(pattern),
                  offsetInMinutes: new Date().getTimezoneOffset(),
                  timeZone:getTimezoneName(),
                  appointmentStatus: "1,2,3,6,7,9,12",
                };
                let queryParams = {
                  startDate: moment(moment().startOf("day")._d).format(pattern),
                  endDate: moment(moment().endOf("day")._d).format(pattern),
                  offsetInMinutes: new Date().getTimezoneOffset(),
                  timeZone:getTimezoneName(),
                };
                dispatch(
                  actions.getAppointments(teamId,clinicianId, appointmentqueryParams)
                );
                dispatch(
                  actions.getCliniciansAppointments(
                    teamId,
                    clinicianId,
                    queryParams
                  ));
                props.loader();
              }
            });
          }
        
        },
        (error) => {
          setconsulationloader(false);
          props.error("Unable to process this request due to internal server error.")
        }
      );
    }
    };
    const setError = (message) => {
      seterrorAlert(<Alert date={new Date()} message={message} />);
    };
    const setIsError = (error) => {
      setisError(error);
    };
    const openHandler = () => {
      setModal(true);
    };
    let modal = null;
    if (showModal && recording)
      modal = <EkgPreviewModal open={showModal} recording={recording} handleClose={handleClose} />;
    const [errorAlert, seterrorAlert] = useState(null);
    let event_time =
      'patientAppointment' in patientDetails
        ? patientDetails.patientAppointment.scheduledStartTime
        : null;
    const deleteAppointmentHandler = () => {
      let current_time = new Date();
      if (new Date(current_time) > new Date(event_time)) {
        props.error('Past sessions cannot be cancelled');
      } else {
        setreassignmodal(false);
        let queryParams = {
          startDateTime: moment(moment().startOf('day')._d).format(pattern),
          endDateTime: moment(moment().endOf('day')._d).format(pattern),
          offsetInMinutes: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        };
        let appointmentqueryParams = {
          startDateTime: moment(moment().startOf('day')._d).format(pattern),
          endDateTime: moment(moment().endOf('day')._d).format(pattern),
          offsetInMinutes: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
          appointmentStatus: '1,2,3,6,7,9',
        };
        let dashboardmetricsParams = {
          startDate: moment(moment().startOf('day')._d).format(pattern),
          endDate: moment(moment().endOf('day')._d).format(pattern),
          offsetInMinutes: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        };
        let deleteparams = {
          version: patientDetails.version,
        };
        dispatch(
          actions.deleteAppointment(
            props.id,
            clinicianId,
            teamId,
            queryParams,
            appointmentqueryParams,
            'dashboardmiddlesection',
            dashboardmetricsParams,
            deleteparams,
          ),
        );
        API.getMetrics(teamId, clinicianId, dashboardmetricsParams).subscribe();
        setConsulationModal(false);
        props.loader && props.loader();
      }
    };
    const reassignAppointment = () => {
      setreassignmodal(true);
    };
    const reassignmodalHandler = () => {
      setreassignmodal(false);
      setConsulationModal(false);
    };
    const changetabvalue = (event, newValue) => {
      settabvalue(newValue);
    };

    const reviewProfile = () => {
      setStepper(1);
      // console.log('upcoming...');
      
      // update appointment status
      let body = {
        appointmentStatus:
          Constants.AppointmentStatusTypes.Physician_Review,
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
      };

      API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
        (res) => {},
        (error) => {}
      );
      setConsulationModal(true);
      setalertCloseFlag(true)
    }

    const waitingroomAlert = () => {
      // if (checkStatus === "Scheduled" || checkStatus === "Physician-Review") {
        setStepper(2);
        // console.log('wait room...');
        // update appointment status
        let body = {
          appointmentStatus:
            Constants.AppointmentStatusTypes.InProgress,
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone:getTimezoneName(),
        };

        API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
          (res) => {},
          (error) => {}
        );
        setConsulationModal(true);
        setalertCloseFlag(true)
      // }
    }

    const alertHandler = async() => {
        let version = "patientAppointment" in patientDetails
        ? patientDetails.patientAppointment.version
        : null;
        let event_time =
          "patientAppointment" in patientDetails
            ? patientDetails.patientAppointment.scheduledStartTime
            : null;
        let event_endTime =
          "patientAppointment" in patientDetails
            ? patientDetails.patientAppointment.scheduledEndTime
            : null;
        let current_time = new Date();
        await API.appointmentStatusCheck(teamId,clinicianId,props.id,version).subscribe(
          (res) => {     
        
         if (res.data.result.reassigned || res.data.result.rescheduled 
          || res.data.result.cancelled || res.data.result.closed) {
          swal({
            closeOnClickOutside: false,
            title: res.data.result.title,
            text: res.data.result.subTitle,
            className: "telekardia",
            buttons: {
              cancel: {
                visible: true,
                text:"",
                className: "swal_cancel swal_black swal_close",
                closeModal: true,
              },
              confirm: {
                text: "OKAY",
                visible: true,
                className: "swal_ok swal_green",
                closeModal: true,
              },
            },
          }).then((res) => {
           
              props.loader();
           
          });
        }
        else{// if(current_time.getTime()>new Date(event_time).getTime()){
          swal({
            closeOnClickOutside: false,
            title: checkStatus === "Scheduled" ? t("Start Consultation") : t("Continue Consultation"),
            text: checkStatus === "Scheduled" ? t("Are you sure you want to start the consultation with") +"  "+ _get(patientDetails,"patient.firstName") +" "+ _get(patientDetails,"patient.lastName") +"?"
            : t("Are you sure you want to continue the consultation with") +"  " +_get(patientDetails,"patient.firstName") +" "+ _get(patientDetails,"patient.lastName") +"?",
            className: "telekardia",
            buttons: {
              cancel: {
                visible: true,
                text:"",
                className: "swal_cancel swal_black swal_close",
                closeModal: true,
              },
              confirm: {
                text: checkStatus === "Scheduled" ? t("START CONSULTATION") : t("CONTINUE CONSULTATION"),
                visible: true,
                className: "swal_ok swal_green",
                closeModal: true,
              },
            },
          }).then((res) => {
            if (res) {
              if (stepper === 1 && checkStatus === "Scheduled") {
                setStepper(1);
      
                // update appointment status
                let body = {
                  appointmentStatus:
                    Constants.AppointmentStatusTypes.Physician_Review,
                  timeZoneOffset: new Date().getTimezoneOffset(),
                  timeZone:getTimezoneName(),
                };
      
                API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
                  (res) => {},
                  (error) => {}
                );
                setConsulationModal(true);
              } else {
                setConsulationModal(true);
              }
            }
          });
        }
        // else{
        //   let message= "You cannot start the consultation now. You can only start the consultation at "+ moment(event_time).format('LT')
        //   props.error(message);
        // }
          },
          (error) => props.error("Unable to process this request due to internal server error."));
        };
    const [consultationNote, setconsultationNote] = useState('');
    const [overReadReport, setoverReadReport] = useState('');
    const [consultationSummary, setconsultationSummary] = useState('');
    const [targetPlan, settargetPlan] = useState('');
    const [lastconsultationNote, setlastconsultationNote] = React.useState('');
    const [lastoverheadreportnote, setlastoverheadreportnote] = React.useState('');
    const [lasttreatmentPlannote, setlasttreatmentPlannote] = React.useState('');
    const [lastconsultationSummaryNotes, setlastconsultationSummaryNotes] = React.useState('');
    const [period, setperiod] = React.useState('No Follow-up');
    const [id, setid] = React.useState(0);
    const [isError, setisError] = useState(false);
    const updateConsultationNote = (note) => {
      setconsultationNote(note);
    };
    //AppoinmentStatusRelated Content

    const [statusModal, setstatusModal] = useState(false);
    const [stepper, setStepper] = useState(1);
    const [tabvalue, settabvalue] = useState(0);
    const [seriespattern, setseriespattern] = useState('Completed');
    const [Reason, setReason] = useState('Patient did not turn up');
    const handleRadioChange = (event) => {
      setseriespattern(event.target.value);
    };
    const handleReasonChange = (event) => {
      setReason(event.target.value);
    };
    const checkStatus = _get(patientDetails, 'patientAppointment.appointmentStatus') || null;
    const appointmentStatusId =
      _get(patientDetails, 'patientAppointment.appointmentStatusId') || null;
    const getAppointmentId = _get(patientDetails, 'appointmentId');

    const startTime = _get(patientDetails, 'patientAppointment.scheduledStartTime')
    const endTime = _get(patientDetails, 'patientAppointment.scheduledEndTime')

    const AUTOSAVE_INTERVAL = 3000;
    React.useEffect(() => {
      const timer = setTimeout(() => {
        if (lastconsultationNote != consultationNote && patientDetails.appointmentId) {
          //consultation notes save
          API.setAppointmentNotes(
            teamId,
            clinicianId,
            patientDetails.appointmentId,
            decodeURI(consultationNote),
          ).subscribe(
            (res) => {
              // do nothing
            },
            (error) => console.log(error),
          );
          setlastconsultationNote(consultationNote);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }, [consultationNote, patientDetails.appointmentId]);

    useEffect(() => {
      const timer2 = setTimeout(() => {
        if (lastoverheadreportnote != overReadReport) {
          API.setOverReadReport(
            teamId,
            clinicianId,
            patientDetails.appointmentId,
            decodeURI(overReadReport),
          ).subscribe(
            (res) => {
              // do nothing
            },
            (error) => console.log(error),
          );
          setlastoverheadreportnote(overReadReport);
        }
      }, AUTOSAVE_INTERVAL);
      return () => clearTimeout(timer2);
    }, [overReadReport]);

    useEffect(() => {
      const timer3 = setTimeout(() => {
        if (lasttreatmentPlannote != targetPlan) {
          API.settreatmentPlan(
            teamId,
            clinicianId,
            patientDetails.appointmentId,
            decodeURI(targetPlan),
          ).subscribe(
            (res) => {
              // do nothing
            },
            (error) => console.log(error),
          );
          setlasttreatmentPlannote(targetPlan);
        }
      }, AUTOSAVE_INTERVAL);
      return () => clearTimeout(timer3);
    }, [targetPlan]);

    useEffect(() => {
      const timer4 = setTimeout(() => {
        if (lastconsultationSummaryNotes != consultationSummary) {
          //consultation notes save
          API.setconsultationSummaryNotes(
            teamId,
            clinicianId,
            patientDetails.appointmentId,
            decodeURI(consultationSummary),
          ).subscribe(
            (res) => {
              // do nothing
            },
            (error) => console.log(error),
          );
          setlastconsultationSummaryNotes(consultationSummary);
        }
      }, AUTOSAVE_INTERVAL);
      return () => clearTimeout(timer4);
    }, [consultationSummary]);

    const [alertCloseFlag, setalertCloseFlag] = useState(false)
    const InProgressHandler = () => {
      // setalertCloseFlag(true)
      let body = {
        appointmentStatus: Constants.AppointmentStatusTypes.InProgress,
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone: getTimezoneName(),
      };

      API.updateAppointmentStatus(teamId, clinicianId, props.id, body).subscribe(
        (res) => {},
        (error) => {},
      );
    };
    
    const SummaryPreparationHandler = () => {
      setrejoinCallFlag(true)
      let body = {
        appointmentStatus: Constants.AppointmentStatusTypes.Encounter_Summary_Preparation,
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone: getTimezoneName(),
      };

      API.updateAppointmentStatus(teamId, clinicianId, props.id, body).subscribe(
        (res) => {},
        (error) => {},
      );
    };
    const stepperNext = () => {
      console.log('with start consultation...');
      
        if (stepper === 1 && checkStatus !== "InProgress") {
          setStepper(stepper + 1);
          //update status to  in progress
          let body = {
            appointmentStatus: Constants.AppointmentStatusTypes.InProgress,
            timeZoneOffset: new Date().getTimezoneOffset(),
            timeZone:getTimezoneName(),
          };
    
          API.updateAppointmentStatus(teamId,clinicianId,props.id, body).subscribe(
            (res) => {},
            (error) => {}
          );
        } else if (stepper === 2 || checkStatus === "InProgress") {
          //alertHandler()
          setstatusModal(true);
    
          // //consultation notes save
          // API.setConsultationNotes(
          //   teamId,
          //   clinicianId,
          //   patientDetails.appointmentId,
          //   decodeURI(consultationNote)
          // ).subscribe(
          //   (res) => {
          //     // do nothing
          //   },
          //   (error) => console.log(error)
          // );
        } else {
          setStepper(1);
        }
      };
    
    const handlestatusConfirm = () => {

      let body = null;
      if (
        (Reason === 'Patient did not turn up' || Reason === 'Patient declined consultation') &&
        seriespattern !== 'Completed'
      ) {
        body = {
          appointmentReasonType: Constants.AppointmentClosedReasons[Reason],
          appointmentStatus: Constants.AppointmentStatusTypes.closed,
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        };

        API.updateAppointmentStatus(teamId, clinicianId, props.id, body).subscribe(
          (res) => {},
          (error) => {},
        );
        let params = {
          startDateTime: moment(moment().startOf('day')._d).format(pattern),
          endDateTime: moment(moment().endOf('day')._d).format(pattern),
          offsetInMinutes: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
          appointmentStatus: '1,2,3,6,7,9,12',
        };
        statusHandleClose();
        setConsulationModal(false);
        setTimeout(() => {
          dispatch(actions.getAppointments(teamId, clinicianId, params));
          props.loader && props.loader();
        }, 1500);
      } else {
        body = {
          appointmentStatus: Constants.AppointmentStatusTypes.Encounter_Summary_Preparation,
          appointmentReasonType:
            seriespattern !== 'Completed' ? Constants.AppointmentClosedReasons[Reason] : '',
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        };

        API.updateAppointmentStatus(teamId, clinicianId, props.id, body).subscribe(
          (res) => {},
          (error) => {},
        );
        // handleConsultationClose();
        setStepper(3);
        statusHandleClose();

        /**
         * Used only for Session Chat
         * Here we reset session chat jid.
         */
        setCurrentAppointmentUser(null);
        //props.alertPresent && props.closeAlert();
      }
    };
    const statusHandleClose = () => {
      setstatusModal(false);
    };
    let appointmentStatusModal = null;
    if (statusModal === true) {
      appointmentStatusModal = (
        <AppointmentStatusModal
          open={statusModal}
          close={statusHandleClose}
          onConfirm={handlestatusConfirm}
          seriespattern={seriespattern}
          Reason={Reason}
          handleRadioChange={handleRadioChange}
          handleReasonChange={handleReasonChange}
        />
      );
    }

    const handleidChange = (event) => {
      if(event.target.value!==0){
        setid(event.target.value);
        API.setheartConditionId(
          teamId,
          clinicianId,
          patientDetails.appointmentId,
          event.target.value
        ).subscribe(
          (res) => {
            // do nothing
          },
          (error) => console.log(error)
        );
        let selectedCondition = heartConditions.filter(x=>x.id===event.target.value);
        setoverReadReport(selectedCondition[0].heartConditionAdditionalText?selectedCondition[0].heartConditionAdditionalText:'');
      }else{
        setoverReadReport("");
        setid(null);
      }
    };

    const handleperiodChange = (event) => {
      API.setdurationText(
        teamId,
        clinicianId,
        patientDetails.appointmentId,
        event.target.value,
      ).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error),
      );
      setperiod(event.target.value);
    };

    const updateoverReadReport = (note) => {
      setoverReadReport(note.target.value);
    };

    const updateconsultationSummary = (note) => {
      // auto save logic here
      setconsultationSummary(note.target.value);
    };

    const updatetargetplan = (note) => {
      // auto save logic here for update treatment plan
      settargetPlan(note.target.value);
    };
    //consultationModalRelated
    const [startcalldisable, setstartcalldisable] = useState(false);
    const disableHandler = (prps) => {
      setstartcalldisable(prps);
    };

    let consultationmodal = null;
    if ('patientAppointment' in patientDetails && startConsultationModal === true) {
      consultationmodal = (
        <Modal
          show={startConsultationModal}
          handleClose={handleConsultationClose}
          view={'consultation'}
          clicked={stepperNext}
          onSubmit={reassignAppointment}
          consultationloader={consultationloader}
          steppervalue={stepper}
          status={checkStatus}
          disableHandler={startcalldisable}
          disable={new Date() > new Date(event_time) ? true : false}
        >
          <div className="right_content">
            <div className="container-fluid">
              <div className="row m-0 mt-2">
                <AppointmentContent
                  {...props}
                  endtimer={waitingroomAlert}
                  clicked={stepperNext}
                  steppervalue={stepper}
                  status={checkStatus}
                  appointmentStatus={checkStatus}
                  setConsultationNote={updateConsultationNote}
                  consultationNote={consultationNote}
                  id={id}
                  setError={setError}
                  isError={isError}
                  setisError={setIsError}
                  Reason={Reason}
                  appointmentId={getAppointmentId}
                  handleidChange={handleidChange}
                  period={period}
                  handleperiodChange={handleperiodChange}
                  overReadReport={overReadReport}
                  updateoverReadReport={updateoverReadReport}
                  consultationSummary={consultationSummary}
                  updateconsultationSummary={updateconsultationSummary}
                  targetPlan={targetPlan}
                  updatetargetplan={updatetargetplan}
                  alert={errorAlert}
                  consultationloader={consultationloader}
                  disableHandler={disableHandler}
                  startcalldisable={startcalldisable}
                  heartConditions={heartConditions}
                  disable={new Date() > new Date(event_time) ? true : false}
                  handleClose={handleConsultationClose}
                  onSubmit={reassignAppointment}
                  tabvalue={tabvalue}
                  changetabvalue={changetabvalue}
                  InProgressHandler={InProgressHandler}
                  SummaryPreparationHandler={SummaryPreparationHandler}
                  startTime={startTime}
                  endTime={endTime}
                  rejoinCallFlag={rejoinCallFlag}
                  inProgressCall={inProgressCall}
                  patientDetails={patientDetails}
                  recording={recording}
                />
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    useEffect(() => {
      // returned function will be called on component unmount
      return () => {
        setModal(false);

        setConsulationModal(false);
        setstatusModal(false);
        setreassignmodal(false);
      };
    }, []);

    return (
      <>
        <WrapperComponent
          {...props}
          checkStatus={(props.id && checkStatus) || null}
          patientDetails={patientDetails}
          recording={recording}
          reassignAppointment={reassignAppointment}
          reviewProfile={reviewProfile}
          waitingroomAlert={waitingroomAlert}
          openHandler={openHandler}
          alertCloseFlag={alertCloseFlag}
        />
        {modal}
        {consultationmodal}
        {appointmentStatusModal}
        {reassignmodalOpen === true && (
          <ReAssignAppointment
            show={reassignmodalOpen}
            teamId={teamId}
            handleClose={reassignmodalHandler}
            onCancel={deleteAppointmentHandler}
            patientDetails={patientDetails}
            {...props}
          />
        )}
      </>
    );
  };
};

export default withStartConsultation;
